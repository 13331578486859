import Mixins from "../../Mixins.js";
import { create } from "ipfs-http-client";

export default {
  name: "EditLoanDocumentChecklist",
  mixins: [Mixins],
  data() {
    return {
      uploadProgressPanel: {
        message: "",
        open: false,
        done: false,
        uploaded: [],
        reset: function() {
          this.message = "";
          this.open = false;
          this.done = false;
          this.uploaded = [];
        },
      },
      property: {
        modal: {
          formInputCollateralFile: false,
          formInputCollateralBindFile: false,
          formInputSp3File: false,
          formInputLoanInstallmentFile: false,
          formInputAgreementFile: false,
          formInputScoringFile: false,
          formInputSubmissionFile: false,
        },
        animation: {
          step6: {
            submitDocumentChecklist: {
              isLoading: false,
            },
          },
        },
      },
      identifier: {
        documentChecklist: {
          identifierEdit: false,
        },
      },
      dataForm: {
        filter: {
          loanAccountNumber: "",
          loanAccountId: "",
        },
        step6: {
          loanAccountId: "",
          collateralExist: false,
          collateralBindExist: false,
          sp3Exist: false,
          installmentExist: false,
          agreementExist: false,
          scoringExist: false,
          submissionExist: false,
          collateralFiles: [],
          collateralBindFiles: [],
          sp3Files: [],
          agreementFiles: [],
          loanInstallmentFiles: [],
          scoringFiles: [],
          submissionFiles: [],
          reset: function() {
            this.loanAccountId = "";
            this.collateralExist = false;
            this.collateralBindExist = false;
            this.sp3Exist = false;
            this.installmentExist = false;
            this.agreementExist = false;
            this.scoringExist = false;
            this.submissionExist = false;
            this.collateralFiles = [];
            this.collateralBindFiles = [];
            this.sp3Files = [];
            this.loanInstallmentFiles = [];
            this.agreementFiles = [];
            this.scoringFiles = [];
            this.submissionFiles = [];
          },
        },
      },
    };
  },
  computed: {
    calculateFileTotalLength() {
      var total =
        this.dataForm.step6.collateralFiles.length +
        this.dataForm.step6.collateralBindFiles.length +
        this.dataForm.step6.sp3Files.length +
        this.dataForm.step6.loanInstallmentFiles.length +
        this.dataForm.step6.agreementFiles.length +
        this.dataForm.step6.scoringFiles.length +
        this.dataForm.step6.submissionFiles.length;
      return total;
    },
  },
  methods: {
    onChangeLoanAccountNumber(event) {
      this.dataForm.step6.reset();
      if (!event) {
        return;
      }
      var l_id = this.mixin_data_options_loan_document_checklist_loan_account.find(
        (data) => data.value === event
      );
      this.dataForm.filter.loanAccountId = l_id.loanAccountId;
      this.getLoanDocumentChecklistByLoanAccountId();
    },
    onSubmitModalUploadCollateralFile(payload) {
      this.dataForm.step6.collateralFiles.push(payload);
      console.log(payload);
    },
    onDeleteCollateralFile(index) {
      this.dataForm.step6.collateralFiles.splice(index, 1);
    },
    onSubmitModalUploadCollateralBindFile(payload) {
      this.dataForm.step6.collateralBindFiles.push(payload);
    },
    onDeleteCollateralBindFile(index) {
      this.dataForm.step6.collateralBindFiles.splice(index, 1);
    },
    onDownloadFiles(item) {
      var file = new Blob([item.documentFile], {
        type: item.documentFile.type,
      });
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = item.documentFile.name;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    onSubmitModalUploadSp3File(payload) {
      this.dataForm.step6.sp3Files.push(payload);
      console.log(payload);
    },
    onDeleteSp3File(index) {
      this.dataForm.step6.sp3Files.splice(index, 1);
    },
    onSubmitModalUploadLoanInstallmentFile(payload) {
      console.log(payload);
      this.dataForm.step6.loanInstallmentFiles.push(payload);
    },
    onDeleteLoanInstallmentFile(index) {
      this.dataForm.step6.loanInstallmentFiles.splice(index, 1);
    },
    onSubmitModalUploadAgreementFile(payload) {
      console.log(payload);
      this.dataForm.step6.agreementFiles.push(payload);
    },
    onDeleteAgreementFile(index) {
      this.dataForm.step6.agreementFiles.splice(index, 1);
    },
    onSubmitModalUploadScoringFile(payload) {
      this.dataForm.step6.scoringFiles.push(payload);
    },
    onDeleteScoringFile(index) {
      this.dataForm.step6.scoringFiles.splice(index, 1);
    },
    onSubmitModalUploadSubmissionFiles(payload) {
      this.dataForm.step6.submissionFiles.push(payload);
    },
    onDeleteSubmissionFile(index) {
      this.dataForm.step6.submissionFiles.splice(index, 1);
    },
    async getLoanDocumentChecklistByLoanAccountId() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-document-checklist/loan-account-id/" +
            this.dataForm.filter.loanAccountId,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step6.createdByUserCode =
            resp.data.data.createdByUserCode;
          this.dataForm.step6.createdByUserName =
            resp.data.data.createdByUserName;
          this.dataForm.step6.createdDate = resp.data.data.createdDate;
          this.dataForm.step6.updatedDate = resp.data.data.updatedDate;
          this.dataForm.step6.updatedByUserCode =
            resp.data.data.updatedByUserCode;
          this.dataForm.step6.updatedByUserName =
            resp.data.data.updatedByUserName;
          this.dataForm.step6.authorizedDate = resp.data.data.authorizedDate;
          this.dataForm.step6.authorizedByUserCode =
            resp.data.data.authorizedByUserCode;
          this.dataForm.step6.authorizedByUserName =
            resp.data.data.authorizedByUserName;
          var response = resp.data.data;
          var collateralFilesConverted = [];
          var collateralBindFilesConverted = [];
          var sp3FilesConverted = [];
          var loanInstallmentFilesConverted = [];
          var agreementFilesConverted = [];
          var scoringFilesConverted = [];
          var submissionFilesConverted = [];
          while (
            collateralFilesConverted.length !== response.collateralFiles.length
          ) {
            var resultConvertCollateralFiles = await this.convertHashToFile(
              response.collateralFiles[collateralFilesConverted.length]
            );
            collateralFilesConverted.push(resultConvertCollateralFiles);
          }
          response.collateralFiles = collateralFilesConverted;
          while (
            collateralBindFilesConverted.length !==
            response.collateralBindFiles.length
          ) {
            var resultConvertCollateralBindFiles = await this.convertHashToFile(
              response.collateralBindFiles[collateralBindFilesConverted.length]
            );
            collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
          }
          response.collateralBindFiles = collateralBindFilesConverted;
          while (sp3FilesConverted.length !== response.sp3Files.length) {
            var resultConvertSp3FilesConverted = await this.convertHashToFile(
              response.sp3Files[sp3FilesConverted.length]
            );
            sp3FilesConverted.push(resultConvertSp3FilesConverted);
          }
          response.sp3Files = sp3FilesConverted;
          while (
            loanInstallmentFilesConverted.length !==
            response.loanInstallmentFiles.length
          ) {
            var resultConvertLoanInstallmentFilesConverted = await this.convertHashToFile(
              response.loanInstallmentFiles[
                loanInstallmentFilesConverted.length
              ]
            );
            loanInstallmentFilesConverted.push(
              resultConvertLoanInstallmentFilesConverted
            );
          }
          response.loanInstallmentFiles = loanInstallmentFilesConverted;

          while (
            agreementFilesConverted.length !== response.agreementFiles.length
          ) {
            var resultConvertAgreementFilesConverted = await this.convertHashToFile(
              response.agreementFiles[agreementFilesConverted.length]
            );
            agreementFilesConverted.push(resultConvertAgreementFilesConverted);
          }
          response.agreementFiles = agreementFilesConverted;
          while (
            scoringFilesConverted.length !== response.scoringFiles.length
          ) {
            var resultConvertScoringFilesConverted = await this.convertHashToFile(
              response.scoringFiles[scoringFilesConverted.length]
            );
            scoringFilesConverted.push(resultConvertScoringFilesConverted);
          }
          response.scoringFiles = scoringFilesConverted;

          while (
            submissionFilesConverted.length !== response.submissionFiles.length
          ) {
            var resultConvertSubmissionFilesConverted = await this.convertHashToFile(
              response.submissionFiles[submissionFilesConverted.length]
            );
            submissionFilesConverted.push(
              resultConvertSubmissionFilesConverted
            );
          }
          response.submissionFiles = submissionFilesConverted;
          this.dataForm.step6 = {
            ...this.dataForm.step6,
            loanAccountId: this.mixin_data_identifier_loan_account_id,
            agreementExist: response.agreementExist,
            agreementFiles: response.agreementFiles,
            collateralBindExist: response.collateralBindExist,
            collateralBindFiles: response.collateralBindFiles,
            collateralExist: response.collateralExist,
            collateralFiles: response.collateralFiles,
            installmentExist: response.installmentExist,
            loanInstallmentFiles: response.loanInstallmentFiles,
            scoringExist: response.scoringExist,
            scoringFiles: response.scoringFiles,
            sp3Exist: response.sp3Exist,
            sp3Files: response.sp3Files,
            submissionExist: response.submissionExist,
            submissionFiles: response.submissionFiles,
          };
          console.log(this.dataForm.step6);
          this.identifier.documentChecklist.identifierEdit = true;
        } else if (resp.data.code === "DATA_NOT_FOUND") {
          this.identifier.documentChecklist.identifierEdit = false;
          this.dataForm.step6.loanAccountId = this.mixin_data_identifier_loan_account_id;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async addLoanDocumentChecklist() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      var payload = {
        ...this.dataForm.step6,
      };
      this.uploadProgressPanel.open = true;
      var collateralFilesConverted = [];
      var collateralBindFilesConverted = [];
      var sp3FilesConverted = [];
      var loanInstallmentFilesConverted = [];
      var agreementFilesConverted = [];
      var scoringFilesConverted = [];
      var submissionFilesConverted = [];
      while (
        collateralFilesConverted.length !== payload.collateralFiles.length
      ) {
        var resultConvertCollateralFiles = await this.convertFileToHash(
          payload.collateralFiles[collateralFilesConverted.length]
        );
        collateralFilesConverted.push(resultConvertCollateralFiles);
      }
      payload.collateralFiles = collateralFilesConverted;
      while (
        collateralBindFilesConverted.length !==
        payload.collateralBindFiles.length
      ) {
        var resultConvertCollateralBindFiles = await this.convertFileToHash(
          payload.collateralBindFiles[collateralBindFilesConverted.length]
        );
        collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
      }
      payload.collateralBindFiles = collateralBindFilesConverted;

      while (sp3FilesConverted.length !== payload.sp3Files.length) {
        var resultSp3FilesConverted = await this.convertFileToHash(
          payload.sp3Files[sp3FilesConverted.length]
        );
        sp3FilesConverted.push(resultSp3FilesConverted);
      }
      payload.sp3Files = sp3FilesConverted;

      while (
        loanInstallmentFilesConverted.length !==
        payload.loanInstallmentFiles.length
      ) {
        var resultLoanInstallmentFilesConverted = await this.convertFileToHash(
          payload.loanInstallmentFiles[loanInstallmentFilesConverted.length]
        );
        loanInstallmentFilesConverted.push(resultLoanInstallmentFilesConverted);
      }
      payload.loanInstallmentFiles = loanInstallmentFilesConverted;
      while (agreementFilesConverted.length !== payload.agreementFiles.length) {
        var resultAgreementFilesConverted = await this.convertFileToHash(
          payload.agreementFiles[agreementFilesConverted.length]
        );
        agreementFilesConverted.push(resultAgreementFilesConverted);
      }
      payload.agreementFiles = agreementFilesConverted;

      while (scoringFilesConverted.length !== payload.scoringFiles.length) {
        var resultScoringFilesConverted = await this.convertFileToHash(
          payload.scoringFiles[scoringFilesConverted.length]
        );
        console.log(payload.scoringFiles);
        scoringFilesConverted.push(resultScoringFilesConverted);
      }
      payload.scoringFiles = scoringFilesConverted;

      while (
        submissionFilesConverted.length !== payload.submissionFiles.length
      ) {
        var resultSubmissionFilesConverted = await this.convertFileToHash(
          payload.submissionFiles[submissionFilesConverted.length]
        );
        submissionFilesConverted.push(resultSubmissionFilesConverted);
      }
      payload.submissionFiles = submissionFilesConverted;

      this.uploadProgressPanel.done = true;
      this.uploadProgressPanel.uploaded = [];
      await this.simpleWait(2000);
      this.uploadProgressPanel.open = false;
      this.uploadProgressPanel.reset();
      this.property.animation.step6.submitDocumentChecklist.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "loan",
          reqUrl: "loan-document-checklist",
          payload: payload,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.step6.submitDocumentChecklist.isLoading = false;
      }
    },
    async editLoanDocumentChecklist() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var confirmation = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      var payload = {
        ...this.dataForm.step6,
      };
      this.uploadProgressPanel.open = true;
      var collateralFilesConverted = [];
      var collateralBindFilesConverted = [];
      var sp3FilesConverted = [];
      var loanInstallmentFilesConverted = [];
      var agreementFilesConverted = [];
      var scoringFilesConverted = [];
      var submissionFilesConverted = [];
      while (
        collateralFilesConverted.length !== payload.collateralFiles.length
      ) {
        var resultConvertCollateralFiles = await this.convertFileToHash(
          payload.collateralFiles[collateralFilesConverted.length]
        );
        collateralFilesConverted.push(resultConvertCollateralFiles);
      }
      payload.collateralFiles = collateralFilesConverted;
      while (
        collateralBindFilesConverted.length !==
        payload.collateralBindFiles.length
      ) {
        var resultConvertCollateralBindFiles = await this.convertFileToHash(
          payload.collateralBindFiles[collateralBindFilesConverted.length]
        );
        collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
      }
      payload.collateralBindFiles = collateralBindFilesConverted;

      while (sp3FilesConverted.length !== payload.sp3Files.length) {
        var resultSp3FilesConverted = await this.convertFileToHash(
          payload.sp3Files[sp3FilesConverted.length]
        );
        sp3FilesConverted.push(resultSp3FilesConverted);
      }
      payload.sp3Files = sp3FilesConverted;

      while (
        loanInstallmentFilesConverted.length !==
        payload.loanInstallmentFiles.length
      ) {
        var resultLoanInstallmentFilesConverted = await this.convertFileToHash(
          payload.loanInstallmentFiles[loanInstallmentFilesConverted.length]
        );
        loanInstallmentFilesConverted.push(resultLoanInstallmentFilesConverted);
      }
      payload.loanInstallmentFiles = loanInstallmentFilesConverted;
      while (agreementFilesConverted.length !== payload.agreementFiles.length) {
        var resultAgreementFilesConverted = await this.convertFileToHash(
          payload.agreementFiles[agreementFilesConverted.length]
        );
        agreementFilesConverted.push(resultAgreementFilesConverted);
      }
      payload.agreementFiles = agreementFilesConverted;

      while (scoringFilesConverted.length !== payload.scoringFiles.length) {
        var resultScoringFilesConverted = await this.convertFileToHash(
          payload.scoringFiles[scoringFilesConverted.length]
        );
        console.log(payload.scoringFiles);
        scoringFilesConverted.push(resultScoringFilesConverted);
      }
      payload.scoringFiles = scoringFilesConverted;

      while (
        submissionFilesConverted.length !== payload.submissionFiles.length
      ) {
        var resultSubmissionFilesConverted = await this.convertFileToHash(
          payload.submissionFiles[submissionFilesConverted.length]
        );
        submissionFilesConverted.push(resultSubmissionFilesConverted);
      }
      payload.submissionFiles = submissionFilesConverted;
      this.uploadProgressPanel.done = true;
      this.uploadProgressPanel.uploaded = [];
      await this.simpleWait(2000);
      this.uploadProgressPanel.open = false;
      this.uploadProgressPanel.reset();
      this.property.animation.step6.submitDocumentChecklist.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "loan",
          reqUrl:
            "loan-document-checklist/loan-account-id/" + payload.loanAccountId,
          payload: payload,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.step6.submitDocumentChecklist.isLoading = false;
      }
    },
    convertFileToHash(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          this.uploadProgressPanel.message = `Uploading : ${payload.documentFile.name}`;
          const ipfs = create(
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DMS_STORAGE_URL
          );
          const data = payload.documentFile;
          const reader = new window.FileReader();
          reader.readAsArrayBuffer(data);
          reader.onload = async () => {
            var convertBuffer = Buffer(reader.result);
            const created = await ipfs.add(convertBuffer, {
              pin: true,
            });
            setTimeout(() => {
              this.uploadProgressPanel.message = "";
              resolve({
                ...payload,
                documentFile: created.path,
              });
              this.uploadProgressPanel.uploaded.push({
                documentFile: payload.documentFile.name,
              });
            }, 2000);
          };
        } catch (error) {
          reject(error);
        } finally {
        }
      });
    },
    convertHashToFile(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const ipfs = create(
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DMS_STORAGE_URL
          );
          let asyncitr = ipfs.cat(payload.documentFile);
          let buffers = [];
          for await (const itr of asyncitr) {
            buffers = [...buffers, Buffer.from(itr)];
          }
          var fileBuffer = Buffer.concat(buffers);
          const blob1 = new Blob([fileBuffer], { type: "application/json" });
          const blob = new Blob([blob1]);
          const fileType = await this.detectFileTypeFromBuffer(fileBuffer);
          const file = new File(
            [blob],
            `${payload.documentNumber}.${fileType.ext}`,
            {
              type: fileType.mime,
            }
          );
          resolve({
            ...payload,
            documentFile: file,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {},
};
