import Mixins from "../../../Mixins.js";

export default {
  name: "EditLoanCollateralVehicle",
  components: {},
  watch: {
    mixin_data_options_loan_collateral_decree_loan_account: function() {
      if (
        this.mixin_data_options_loan_collateral_decree_loan_account.length !== 0
      ) {
        this.getSessionLoanAccountNumber();
      }
    },
  },
  mixins: [Mixins],
  data() {
    return {
      property: {
        modal: {
          step4: {
            vehicle: {
              showModalEditVehicle: false,
            },
          },
        },
        listElement: {
          step4: {
            vehicle: {
              currentPage: 1,
              rows: 0,
              perPage: 5,
              downloading: false,
              message: "",
            },
          },
        },
        animation: {
          step4: {
            vehicle: {
              add: {
                isLoading: false,
              },
              edit: {
                isLoading: false,
              },
            },
          },
        },
      },
      territoryVariableHelper: {
        step4: {
          vehicle: {
            add: {
              provinceId: 0,
              cityId: 0,
              districtId: 0,
            },
            edit: {
              provinceId: 0,
              cityId: 0,
              districtId: 0,
            },
          },
        },
      },
      options: {
        step4: {
          vehicle: {
            pilihNomorKontrak: [],
            kategoriJaminan: [],
            statusJaminan: [],
            jenisKendaraan: [],
            bahanBakar: [],
            jenisPengikatan: [],
            add: {
              provinsi: [],
              kabupaten: [],
              kecamatan: [],
              kelurahan: [],
            },
            edit: {
              provinsi: [],
              kabupaten: [],
              kecamatan: [],
              kelurahan: [],
            },
          },
        },
      },
      table: {
        header: {
          step4: {
            vehicle: [],
          },
        },
        data: {
          step4: {
            vehicle: [],
          },
        },
      },
      dataForm: {
        step4: {
          vehicle: {
            add: {
              issuedDate: "",
              loanAccountNumber: "",
              pilihNomorKontrak: "",
              collateralNumber: "",
              bpkbNumber: "",
              chassisNumber: "",
              machineNumber: "",
              plateNumber: "",
              invoiceNumber: "",
              vehicleBrand: "",
              vehicleType: "",
              vehicleColor: "",
              cylinderCapacity: "",
              assamblyYear: "",
              productionYear: "",
              ownerName: "",
              effectiveDate: "",
              marketValue: 0,
              collateralAddress: "",
              postalCode: "",
              villageId: "",
              estimatedDate: "",
              estimatedValue: 0,
              appraisalValue: 0,
              description: "",
              loanAccountId: "",
              loanCollateralStatusId: "",
              collateralFuelTypeId: "",
              collateralVehicleTypeId: "",
              loanCollateralId: "",
              rloanCollateralCategoryId: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_LOAN_COLLATERAL_CATEGORY_VEHICLE,
              loanCollateralBindTypeId: "",
              validThru: "",
            },
            edit: {
              issuedDate: "",
              loanAccountNumber: "",
              collateralNumber: "",
              bpkbNumber: "",
              chassisNumber: "",
              machineNumber: "",
              plateNumber: "",
              invoiceNumber: "",
              vehicleBrand: "",
              vehicleType: "",
              vehicleColor: "",
              cylinderCapacity: "",
              assamblyYear: "",
              productionYear: "",
              ownerName: "",
              effectiveDate: "",
              marketValue: 0,
              collateralAddress: "",
              postalCode: "",
              villageId: "",
              estimatedDate: "",
              estimatedValue: 0,
              appraisalValue: 0,
              description: "",
              loanAccountId: "",
              loanCollateralStatusId: "",
              collateralFuelTypeId: "",
              collateralVehicleTypeId: "",
              loanCollateralId: "",
              rloanCollateralCategoryId: "",
              loanCollateralVehicleIdSelected: "",
              loanCollateralBindTypeId: "",
              validThru: "",
              createdByUserCode: "",
              createdByUserName: "",
              createdDate: "",
              updatedDate: "",
              updatedByUserCode: "",
              updatedByUserName: "",
              authorizedDate: "",
              authorizedByUserCode: "",
              authorizedByUserName: "",
            },
          },
        },
      },
    };
  },
  methods: {
    getSessionLoanAccountNumber() {
      var query = this.$route.query.refId;
      this.dataForm.step4.vehicle.add.pilihNomorKontrak = JSON.parse(
        this.decryptBASE64(query)
      ).loanAccountNumber;
      this.dataForm.step4.vehicle.add.loanAccountNumber = JSON.parse(
        this.decryptBASE64(query)
      ).loanAccountNumber;
      setTimeout(() => {
        this.changeOptionSelectContractNumberLoanCollateralVehicle();
      }, 10000);
    },
    closeModalEditLoanCollateralVehicle() {
      this.property.modal.step4.vehicle.showModalEditVehicle = false;
      this.resetFormLoanCollateralVehicle("EDIT");
    },
    resetFormLoanCollateralVehicle(type) {
      switch (type) {
        case "ADD":
          this.dataForm.step4.vehicle.add.collateralNumber = "";
          this.dataForm.step4.vehicle.add.bpkbNumber = "";
          this.dataForm.step4.vehicle.add.chassisNumber = "";
          this.dataForm.step4.vehicle.add.machineNumber = "";
          this.dataForm.step4.vehicle.add.plateNumber = "";
          this.dataForm.step4.vehicle.add.invoiceNumber = "";
          this.dataForm.step4.vehicle.add.vehicleBrand = "";
          this.dataForm.step4.vehicle.add.vehicleType = "";
          this.dataForm.step4.vehicle.add.vehicleColor = "";
          this.dataForm.step4.vehicle.add.cylinderCapacity = "";
          this.dataForm.step4.vehicle.add.assamblyYear = "";
          this.dataForm.step4.vehicle.add.productionYear = "";
          this.dataForm.step4.vehicle.add.ownerName = "";
          this.dataForm.step4.vehicle.add.effectiveDate = "";
          this.dataForm.step4.vehicle.add.marketValue = 0;
          this.dataForm.step4.vehicle.add.collateralAddress = "";
          this.dataForm.step4.vehicle.add.postalCode = "";
          this.dataForm.step4.vehicle.add.villageId = "";
          this.dataForm.step4.vehicle.add.estimatedDate = "";
          this.dataForm.step4.vehicle.add.estimatedValue = 0;
          this.dataForm.step4.vehicle.add.appraisalValue = 0;
          this.dataForm.step4.vehicle.add.description = "";
          this.dataForm.step4.vehicle.add.loanCollateralStatusId = "";
          this.dataForm.step4.vehicle.add.collateralFuelTypeId = "";
          this.dataForm.step4.vehicle.add.collateralVehicleTypeId = "";
          this.dataForm.step4.vehicle.add.loanCollateralId = "";
          this.dataForm.step4.vehicle.add.rloanCollateralCategoryId = "";
          this.territoryVariableHelper.step4.vehicle.add.cityId = 0;
          this.territoryVariableHelper.step4.vehicle.add.districtId = 0;
          this.territoryVariableHelper.step4.vehicle.add.provinceId = 0;
          this.options.step4.vehicle.add.kabupaten = [];
          this.options.step4.vehicle.add.kecamatan = [];
          this.options.step4.vehicle.add.kelurahan = [];
          this.dataForm.step4.vehicle.add.loanCollateralBindTypeId = "";
          break;

        case "EDIT":
          this.dataForm.step4.vehicle.edit.collateralAddress = "";
          this.dataForm.step4.vehicle.edit.loanAccountId = "";
          this.dataForm.step4.vehicle.edit.loanCollateralVehicleIdSelected = "";
          this.dataForm.step4.vehicle.edit.loanAccountNumber = "";
          this.dataForm.step4.vehicle.edit.collateralNumber = "";
          this.dataForm.step4.vehicle.edit.bpkbNumber = "";
          this.dataForm.step4.vehicle.edit.chassisNumber = "";
          this.dataForm.step4.vehicle.edit.machineNumber = "";
          this.dataForm.step4.vehicle.edit.plateNumber = "";
          this.dataForm.step4.vehicle.edit.invoiceNumber = "";
          this.dataForm.step4.vehicle.edit.vehicleBrand = "";
          this.dataForm.step4.vehicle.edit.vehicleType = "";
          this.dataForm.step4.vehicle.edit.vehicleColor = "";
          this.dataForm.step4.vehicle.edit.cylinderCapacity = "";
          this.dataForm.step4.vehicle.edit.assamblyYear = "";
          this.dataForm.step4.vehicle.edit.productionYear = "";
          this.dataForm.step4.vehicle.edit.ownerName = "";
          this.dataForm.step4.vehicle.edit.effectiveDate = "";
          this.dataForm.step4.vehicle.edit.marketValue = 0;
          this.dataForm.step4.vehicle.edit.collateraleditress = "";
          this.dataForm.step4.vehicle.edit.postalCode = "";
          this.dataForm.step4.vehicle.edit.villageId = "";
          this.dataForm.step4.vehicle.edit.estimatedDate = "";
          this.dataForm.step4.vehicle.edit.estimatedValue = 0;
          this.dataForm.step4.vehicle.edit.appraisalValue = 0;
          this.dataForm.step4.vehicle.edit.description = "";
          this.dataForm.step4.vehicle.edit.loanCollateralStatusId = "";
          this.dataForm.step4.vehicle.edit.collateralFuelTypeId = "";
          this.dataForm.step4.vehicle.edit.collateralVehicleTypeId = "";
          this.dataForm.step4.vehicle.edit.loanCollateralId = "";
          this.dataForm.step4.vehicle.edit.rloanCollateralCategoryId = "";
          this.territoryVariableHelper.step4.vehicle.edit.cityId = 0;
          this.territoryVariableHelper.step4.vehicle.edit.districtId = 0;
          this.territoryVariableHelper.step4.vehicle.edit.provinceId = 0;
          this.options.step4.vehicle.edit.kabupaten = [];
          this.options.step4.vehicle.edit.kecamatan = [];
          this.options.step4.vehicle.edit.kelurahan = [];
          this.dataForm.step4.vehicle.edit.loanCollateralBindTypeId = "";
          break;

        default:
          return;
          break;
      }
    },
    changeOptionSelectContractNumberLoanCollateralVehicle() {
      if (this.dataForm.step4.vehicle.add.pilihNomorKontrak === "") {
        this.table.data.step4.vehicle = [];
        this.property.listElement.step4.vehicle.rows = 0;
        this.dataForm.step4.vehicle.add.loanAccountNumber = "";
      } else {
        this.mixin_data_options_loan_collateral_vehicle_loan_account.map(
          (i) => {
            if (i.value === this.dataForm.step4.vehicle.add.pilihNomorKontrak) {
              this.dataForm.step4.vehicle.add.loanAccountNumber =
                i.loanAccountNumber;
              this.dataForm.step4.vehicle.add.loanAccountId = i.loanAccountId;
              this.getLoanCollateralVehicle();
            }
          }
        );
      }
    },
    async getLoanCollateralVehicle() {
      this.table.data.step4.vehicle = [];
      this.property.listElement.step4.vehicle.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-vehicle",
          payload: {
            collateralNumber: "",
            loanAccountNumber: this.dataForm.step4.vehicle.add
              .pilihNomorKontrak,
            page: 0,
            size: this.property.listElement.step4.vehicle.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step4.vehicle = resp.data.data.content;
            this.property.listElement.step4.vehicle.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCollateralVehicle(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralVehicle(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step4.vehicle.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataCollateralVehicle(resp) {
      this.table.data.step4.vehicle = [];
      this.property.listElement.step4.vehicle.rows = 0;
      this.property.listElement.step4.vehicle.message = resp.data.message;
    },
    handleErrorLoanCollateralVehicle(error) {
      console.log(error.response);
      this.table.data.step4.vehicle = [];
      this.property.listElement.step4.vehicle.rows = 0;
      this.property.listElement.step4.vehicle.currentPage = 1;
      this.property.listElement.step4.vehicle.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanCollateralVehicle(event) {
      this.table.data.step4.vehicle = [];
      this.property.listElement.step4.vehicle.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-vehicle",
          payload: {
            collateralNumber: "",
            loanAccountNumber: this.dataForm.step4.vehicle.add
              .pilihNomorKontrak,
            page: event - 1,
            size: this.property.listElement.step4.vehicle.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step4.vehicle = resp.data.data.content;
            this.property.listElement.step4.vehicle.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCollateralVehicle(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralVehicle(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step4.vehicle.downloading = false;
        }, timeout);
      }
    },
    async deleteLoanCollateralVehicle(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-vehicle/" +
                  props.row.loanAccountCollateralId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getLoanCollateralVehicle();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getLoanCollateralVehicleById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-collateral-vehicle/" + props.row.loanAccountCollateralId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step4.vehicle.edit.loanCollateralVehicleIdSelected =
              resp.data.data.loanAccountCollateralId;
            this.dataForm.step4.vehicle.edit.loanAccountNumber = resp.data.data
              .tloanAccountResponseDto
              ? resp.data.data.tloanAccountResponseDto.loanAccountNumber
              : "";
            this.dataForm.step4.vehicle.edit.collateralNumber =
              resp.data.data.collateralNumber;
            this.dataForm.step4.vehicle.edit.bpkbNumber =
              resp.data.data.bpkbNumber;
            this.dataForm.step4.vehicle.edit.chassisNumber =
              resp.data.data.chassisNumber;
            this.dataForm.step4.vehicle.edit.machineNumber =
              resp.data.data.machineNumber;
            this.dataForm.step4.vehicle.edit.plateNumber =
              resp.data.data.plateNumber;
            this.dataForm.step4.vehicle.edit.invoiceNumber =
              resp.data.data.invoiceNumber;
            this.dataForm.step4.vehicle.edit.vehicleBrand =
              resp.data.data.vehicleBrand;
            this.dataForm.step4.vehicle.edit.vehicleColor =
              resp.data.data.vehicleColor;
            this.dataForm.step4.vehicle.edit.cylinderCapacity =
              resp.data.data.cylinderCapacity;
            this.dataForm.step4.vehicle.edit.assamblyYear =
              resp.data.data.assamblyYear;
            this.dataForm.step4.vehicle.edit.productionYear =
              resp.data.data.productionYear;
            this.dataForm.step4.vehicle.edit.ownerName =
              resp.data.data.ownerName;
            this.dataForm.step4.vehicle.edit.effectiveDate =
              resp.data.data.effectiveDate;
            this.dataForm.step4.vehicle.edit.marketValue =
              resp.data.data.marketValue;
            this.dataForm.step4.vehicle.edit.collateralAddress =
              resp.data.data.collateralAddress;
            this.dataForm.step4.vehicle.edit.postalCode =
              resp.data.data.postalCode;
            this.dataForm.step4.vehicle.edit.villageId =
              resp.data.data.villageId;
            this.dataForm.step4.vehicle.edit.validThru =
              resp.data.data.validThru;
            this.dataForm.step4.vehicle.edit.issuedDate =
              resp.data.data.issuedDate;
            this.dataForm.step4.vehicle.edit.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step4.vehicle.edit.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step4.vehicle.edit.createdDate =
              resp.data.data.createdDate;
            this.dataForm.step4.vehicle.edit.updatedDate =
              resp.data.data.updatedDate;
            this.dataForm.step4.vehicle.edit.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step4.vehicle.edit.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step4.vehicle.edit.authorizedDate =
              resp.data.data.authorizedDate;
            this.dataForm.step4.vehicle.edit.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step4.vehicle.edit.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.getAreaVillageByIdInLoanCollateralVehicle();
            this.dataForm.step4.vehicle.edit.estimatedDate =
              resp.data.data.estimatedDate;
            this.dataForm.step4.vehicle.edit.estimatedValue =
              resp.data.data.estimatedValue;
            this.dataForm.step4.vehicle.edit.appraisalValue =
              resp.data.data.appraisalValue;
            this.dataForm.step4.vehicle.edit.description =
              resp.data.data.description;
            this.dataForm.step4.vehicle.edit.loanAccountId = resp.data.data
              .tloanAccountResponseDto
              ? resp.data.data.tloanAccountResponseDto.loanAccountId
              : "";
            this.dataForm.step4.vehicle.edit.loanCollateralStatusId = resp.data
              .data.rloanCollateralStatusDto
              ? resp.data.data.rloanCollateralStatusDto.rloanCollateralStatusId
              : "";
            this.dataForm.step4.vehicle.edit.collateralFuelTypeId = resp.data
              .data.rloanCollateralFuelTypeDto
              ? resp.data.data.rloanCollateralFuelTypeDto.collateralFuelTypeId
              : "";
            this.dataForm.step4.vehicle.edit.collateralVehicleTypeId = resp.data
              .data.rloanCollateralVehicleTypeDto
              ? resp.data.data.rloanCollateralVehicleTypeDto
                  .collateralVehicleTypeId
              : "";
            this.dataForm.step4.vehicle.edit.loanCollateralId =
              resp.data.data.loanCollateralId;
            this.dataForm.step4.vehicle.edit.rloanCollateralCategoryId = resp
              .data.data.rloanCollateralCategoryDto
              ? resp.data.data.rloanCollateralCategoryDto
                  .rloanCollateralCategoryId
              : "";
            this.dataForm.step4.vehicle.edit.loanCollateralBindTypeId = resp
              .data.data.rloanCollateralBindTypeDto
              ? resp.data.data.rloanCollateralBindTypeDto.collateralBindTypeId
              : "";
            this.property.modal.step4.vehicle.showModalEditVehicle = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async getAreaVillageByIdInLoanCollateralVehicle() {
      const payload = {
        id: this.dataForm.step4.vehicle.edit.villageId,
      };
      this.$store
        .dispatch("GET_MASTER_AREA_VILLAGE_BY_ID", payload)
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.territoryVariableHelper.step4.vehicle.edit.provinceId = resp
              .data.data.mdistrictDto
              ? resp.data.data.mdistrictDto.mcityDto
                ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto
                  ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto.provinceId
                  : ""
                : ""
              : "";
            this.territoryVariableHelper.step4.vehicle.edit.cityId = resp.data
              .data.mdistrictDto
              ? resp.data.data.mdistrictDto.mcityDto
                ? resp.data.data.mdistrictDto.mcityDto.cityId
                : ""
              : "";
            this.territoryVariableHelper.step4.vehicle.edit.districtId = resp
              .data.data.mdistrictDto
              ? resp.data.data.mdistrictDto.districtId
              : "";
            this.changeSelectProvinsiEditLoanCollateralVehicle();
            this.changeSelectKabupatenEditLoanCollateralVehicle();
            this.changeSelectKecamatanEditLoanCollateralVehicle();
          }
        });
    },
    changeSelectProvinsiEditLoanCollateralVehicle() {
      this.options.step4.vehicle.edit.kabupaten = [];
      this.options.step4.vehicle.edit.kecamatan = [];
      this.options.step4.vehicle.edit.kelurahan = [];

      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/city",
          params: {
            provinceId: this.territoryVariableHelper.step4.vehicle.edit
              .provinceId,
            cityName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.cityId;
            const text = i.cityName;
            this.options.step4.vehicle.edit.kabupaten.push({ value, text });
          });
        });
    },
    changeSelectKabupatenEditLoanCollateralVehicle() {
      this.options.step4.vehicle.edit.kecamatan = [];
      this.options.step4.vehicle.edit.kelurahan = [];

      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/district",
          params: {
            cityId: this.territoryVariableHelper.step4.vehicle.edit.cityId,
            districtName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.districtId;
            const text = i.districtName;
            this.options.step4.vehicle.edit.kecamatan.push({ text, value });
          });
        });
    },
    changeSelectKecamatanEditLoanCollateralVehicle() {
      this.options.step4.vehicle.edit.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/village",
          params: {
            districtId: this.territoryVariableHelper.step4.vehicle.edit
              .districtId,
            villageName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.villageId;
            const text = i.villageName;
            const postalcode = i.postalcode;
            this.options.step4.vehicle.edit.kelurahan.push({
              text,
              value,
              postalcode,
            });
          });
        });
    },
    async editLoanCollateralVehicle() {
      const payload = {
        collateralNumber: this.dataForm.step4.vehicle.edit.collateralNumber,
        bpkbNumber: this.dataForm.step4.vehicle.edit.bpkbNumber,
        chassisNumber: this.dataForm.step4.vehicle.edit.chassisNumber,
        machineNumber: this.dataForm.step4.vehicle.edit.machineNumber,
        plateNumber: this.dataForm.step4.vehicle.edit.plateNumber,
        invoiceNumber: this.dataForm.step4.vehicle.edit.invoiceNumber,
        vehicleBrand: this.dataForm.step4.vehicle.edit.vehicleBrand,
        vehicleType: this.dataForm.step4.vehicle.edit.vehicleType,
        vehicleColor: this.dataForm.step4.vehicle.edit.vehicleColor,
        cylinderCapacity: this.dataForm.step4.vehicle.edit.cylinderCapacity,
        assamblyYear: this.dataForm.step4.vehicle.edit.assamblyYear,
        productionYear: this.dataForm.step4.vehicle.edit.productionYear,
        ownerName: this.dataForm.step4.vehicle.edit.ownerName,
        marketValue: this.dataForm.step4.vehicle.edit.marketValue,
        collateralAddress: this.dataForm.step4.vehicle.edit.collateralAddress,
        postalCode: this.dataForm.step4.vehicle.edit.postalCode,
        villageId: this.dataForm.step4.vehicle.edit.villageId,
        estimatedDate: this.dataForm.step4.vehicle.edit.estimatedDate,
        estimatedValue: this.dataForm.step4.vehicle.edit.estimatedValue,
        appraisalValue: this.dataForm.step4.vehicle.edit.appraisalValue,
        description: this.dataForm.step4.vehicle.edit.description,
        loanAccountId: this.dataForm.step4.vehicle.edit.loanAccountId,
        loanCollateralStatusId: this.dataForm.step4.vehicle.edit
          .loanCollateralStatusId,
        collateralFuelTypeId: this.dataForm.step4.vehicle.edit
          .collateralFuelTypeId,
        collateralVehicleTypeId: this.dataForm.step4.vehicle.edit
          .collateralVehicleTypeId,
        loanCollateralId: this.dataForm.step4.vehicle.edit.loanCollateralId,
        rloanCollateralCategoryId: this.dataForm.step4.vehicle.edit
          .rloanCollateralCategoryId,
        loanCollateralBindTypeId: this.dataForm.step4.vehicle.edit
          .loanCollateralBindTypeId,
        validThru: this.dataForm.step4.vehicle.edit.validThru,
        issuedDate: this.dataForm.step4.vehicle.edit.issuedDate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step4.vehicle.edit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-vehicle/" +
                  this.dataForm.step4.vehicle.edit
                    .loanCollateralVehicleIdSelected,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.step4.vehicle.showModalEditVehicle = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormLoanCollateralVehicle("EDIT");
                      this.getLoanCollateralVehicle();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step4.vehicle.edit.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    handleChangeProvinceAddVehicleCollateral() {
      this.options.step4.vehicle.add.kabupaten = [];
      this.options.step4.vehicle.add.kecamatan = [];
      this.options.step4.vehicle.add.kelurahan = [];

      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/city",
          params: {
            provinceId: this.territoryVariableHelper.step4.vehicle.add
              .provinceId,
            cityName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.cityId;
            const text = i.cityName;
            this.options.step4.vehicle.add.kabupaten.push({ value, text });
          });
        });
    },
    handleChangeKabupatenAddVehicleCollateral() {
      this.options.step4.vehicle.add.kecamatan = [];
      this.options.step4.vehicle.add.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/district",
          params: {
            cityId: this.territoryVariableHelper.step4.vehicle.add.cityId,
            districtName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.districtId;
            const text = i.districtName;
            this.options.step4.vehicle.add.kecamatan.push({ text, value });
          });
        });
    },
    handleChangeKelurahanAddVehicleCollateral() {
      this.options.step4.vehicle.add.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/village",
          params: {
            districtId: this.territoryVariableHelper.step4.vehicle.add
              .districtId,
            villageName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.villageId;
            const text = i.villageName;
            const postalcode = i.postalcode;
            this.options.step4.vehicle.add.kelurahan.push({
              text,
              value,
              postalcode,
            });
          });
        });
    },
    getReferenceLoanCollateralStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-status",
          params: {
            groupCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_GROUP_CODE_COLLATERAL_STATUS_01,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.rloanCollateralStatusId;
              const text = i.collateralStatusName;
              this.options.step4.vehicle.statusJaminan.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanCollateralVehicle() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-vehicle-type",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.collateralVehicleTypeId;
              const text = i.collateralVehicleTypeName;
              this.options.step4.vehicle.jenisKendaraan.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanCollateralFuelType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-fuel-type",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.collateralFuelTypeId;
              const text = i.collateralFuelTypeName;
              this.options.step4.vehicle.bahanBakar.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanCollateralBindType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-bind-type",
          params: {
            collateralBindTypeCodes: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_BIND_TYPE_FEO,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.collateralBindTypeId;
              const text = i.collateralBindTypeName;
              this.options.step4.vehicle.jenisPengikatan.push({ text, value });
            });
          }
        });
    },
    getReferenceProvince() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/province",
          params: {
            provinceName: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const value = i.provinceId;
              const text = i.provinceName;
              this.options.step4.vehicle.add.provinsi.push({ text, value });
              this.options.step4.vehicle.edit.provinsi.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanCollateralCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-category",
          params: {
            collateralCategoryCodes: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_CATEGORY_VEHICLE,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.rloanCollateralCategoryId;
              const text = i.collateralCategoryName;
              this.options.step4.vehicle.kategoriJaminan.push({ value, text });
            });
          }
        });
    },
    async addLoanCollateralVehicle() {
      const payload = {
        bpkbNumber: this.dataForm.step4.vehicle.add.bpkbNumber,
        chassisNumber: this.dataForm.step4.vehicle.add.chassisNumber,
        machineNumber: this.dataForm.step4.vehicle.add.machineNumber,
        plateNumber: this.dataForm.step4.vehicle.add.plateNumber,
        invoiceNumber: this.dataForm.step4.vehicle.add.invoiceNumber,
        collateralNumber: this.dataForm.step4.vehicle.add.collateralNumber,
        vehicleBrand: this.dataForm.step4.vehicle.add.vehicleBrand,
        vehicleColor: this.dataForm.step4.vehicle.add.vehicleColor,
        assamblyYear: this.dataForm.step4.vehicle.add.assamblyYear,
        productionYear: this.dataForm.step4.vehicle.add.productionYear,
        ownerName: this.dataForm.step4.vehicle.add.ownerName,
        marketValue: this.dataForm.step4.vehicle.add.marketValue,
        collateralAddress: this.dataForm.step4.vehicle.add.collateralAddress,
        postalCode: this.dataForm.step4.vehicle.add.postalCode,
        villageId: this.dataForm.step4.vehicle.add.villageId,
        estimatedDate: this.dataForm.step4.vehicle.add.estimatedDate,
        estimatedValue: this.dataForm.step4.vehicle.add.estimatedValue,
        appraisalValue: this.dataForm.step4.vehicle.add.appraisalValue,
        description: this.dataForm.step4.vehicle.add.description,
        loanAccountId: this.dataForm.step4.vehicle.add.loanAccountId,
        loanCollateralStatusId: this.dataForm.step4.vehicle.add
          .loanCollateralStatusId,
        collateralFuelTypeId: this.dataForm.step4.vehicle.add
          .collateralFuelTypeId,
        collateralVehicleTypeId: this.dataForm.step4.vehicle.add
          .collateralVehicleTypeId,
        rloanCollateralCategoryId: this.dataForm.step4.vehicle.add
          .rloanCollateralCategoryId,
        loanCollateralBindTypeId: this.dataForm.step4.vehicle.add
          .loanCollateralBindTypeId,
        validThru: this.dataForm.step4.vehicle.add.validThru,
        issuedDate: this.dataForm.step4.vehicle.add.issuedDate,
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step4.vehicle.add.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "loan-collateral-vehicle",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.resetFormLoanCollateralVehicle("ADD");
                    this.getLoanCollateralVehicle();
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step4.vehicle.add.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getSystemDate() {
      this.dataForm.step4.vehicle.add.issuedDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step4.vehicle.add.validThru = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step4.vehicle.add.estimatedDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getSystemDate();
    this.getReferenceProvince();
    this.getReferenceLoanCollateralCategory();
    this.getReferenceLoanCollateralStatus();
    this.getReferenceLoanCollateralVehicle();
    this.getReferenceLoanCollateralFuelType();
    this.getReferenceLoanCollateralBindType();
  },
};
