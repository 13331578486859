import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Mixins from "../../Mixins.js";
import LoanInstallmentUploadForm from "@/components/page/loan-installment-upload-form";

export default {
  name: "EditLoanInstallment",
  components: {
    LoanInstallmentUploadForm,
  },
  mixins: [Mixins],
  watch: {
    mixin_data_options_loan_installment_loan_account: function() {
      if (this.mixin_data_options_loan_installment_loan_account.length !== 0) {
        this.getSessionLoanAccountID();
      }
    },
  },
  data() {
    return {
      property: {
        modal: {
          step3: {
            showModalEditMarginInstallment: false,
          },
        },
        listElement: {
          step3: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
        animation: {
          isDownloadingFile: false,
          cetakNasabah: {
            isLoading: false,
          },
          step3: {
            processLoanInstallment: {
              isLoading: false,
            },
            updateMargin: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step3: {
          loanData: {
            timePeriod: "",
            endDate: "",
            nominalLoan: "",
            loanAkadTypeAcronym: "",
          },
          isComaDelimiter: true,
          tunggakanMargin: 0,
          tunggakanPokok: 0,
          totalPokok: 0,
          pilihNomorKontrak: "",
          billDay: "",
          contractNumber: "",
          cifIdName: "",
          realitationDate: "",
          dueDate: "",
          nominalLoan: "",
          totalInstallment: "",
          totalMargin: "",
          totalPeriod: 0,
          principal: 0,
          margin: 0,
          downloading: false,
          message: "",
          updateMargin: {
            newMargin: 0,
            loanInstallmentId: "",
            newPrincipal: 0,
          },
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
      },
      options: {
        step3: {
          pilihNomorKontrak: [],
        },
      },
      table: {
        data: {
          step3: [],
        },
        header: {
          step3: [
            {
              key: "no",
              label: "No",
            },
            {
              key: "principalRemain",
              label: "Sisa Pokok",
            },
            {
              key: "principal",
              label: "Pokok",
            },
            {
              key: "margin",
              label: "Margin",
            },
            {
              key: "installment",
              label: "Total Angsuran",
            },
            {
              key: "rloanPaymentStatus.paymentStatusName",
              label: "Status Pembayaran",
            },
            {
              key: "rstatus.statusName",
              label: "Status",
            },
            {
              key: "actions",
              label: "Aksi",
            },
          ],
        },
      },
    };
  },
  methods: {
    async onDownloadTemplate(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountId: this.dataForm.step3.pilihNomorKontrak,
        isComaDelimiter: this.dataForm.step3.isComaDelimiter,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/remodify-upload-installment",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Angsuran_${this.dataForm.step3.pilihNomorKontrak}.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFile(fileType) {
      console.log("filetype", fileType);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        loanAccountNumber: this.dataForm.step3.contractNumber,
        name: this.dataForm.step3.cifIdName,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/angsuran-nasabah/files",
          payload: payload,
        });
        console.log("download", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_angsuran_nasabah_${payload.loanAccountNumber}.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onPrint() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountNumber: this.dataForm.step3.contractNumber,
        fileType: "pdf",
        name: this.dataForm.step3.cifIdName,
      };
      this.property.animation.cetakNasabah.isLoading = true;
      try {
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/angsuran-nasabah/files",
          payload: payload,
        });
        this.printOutWindow(response.data);

        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.cetakNasabah.isLoading = false;
      }
    },
    onUploadLoanInstallment() {
      this.$refs.loanInstallmentUploadFormTRef.onOpenModal();
    },
    routeToPageAddLoanInstallmentManualPayment(props) {
      this.$router.push(
        `/loan/installment/manual-payment/new?refId=${this.encryptBASE64(
          JSON.stringify({
            loanAccountNumber: props.row.loanAccountNumber,
          })
        )}`
      );
    },
    getSessionLoanAccountID() {
      const getTokenFromSession = this.mixin_data_identifier_loan_account_id;
      this.dataForm.step3.pilihNomorKontrak = getTokenFromSession;
      console.log("respose", getTokenFromSession);
      setTimeout(() => {
        this.changeOptionSelectContractNumberLoanInstallment();
      }, 5000);
    },
    async changeOptionSelectContractNumberLoanInstallment() {
      this.resetFormRequestResponseLoanInstallment();
      this.property.listElement.step3.downloading = true;
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (this.dataForm.step3.pilihNomorKontrak === "") {
          setTimeout(() => {
            this.property.listElement.step3.downloading = false;
            this.property.listElement.step3.message =
              "Pilih Nomor Kontrak Terlebih Dahulu";
          }, timeout);
        } else {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "loan",
              reqUrl:
                "loan-installment/findByLoanAccountId/" +
                this.dataForm.step3.pilihNomorKontrak,
            });
            this.getLoanAccountByIdInLoanInstallment();
            if (resp.data.code === "SUCCESS") {
              this.dataForm.step3.billDay = resp.data.data.billDay;
              this.dataForm.step3.contractNumber =
                resp.data.data.contractNumber;
              this.dataForm.step3.cifIdName = resp.data.data.cifIdName;
              this.dataForm.step3.realitationDate =
                resp.data.data.realitationDate;
              this.dataForm.step3.dueDate = resp.data.data.dueDate;
              this.dataForm.step3.nominalLoan = resp.data.data.nominalLoan;
              this.dataForm.step3.totalInstallment =
                resp.data.data.totalInstallment;
              this.dataForm.step3.totalMargin = resp.data.data.totalMargin;
              this.dataForm.step3.totalPokok = resp.data.data.totalPokok;
              this.dataForm.step3.tunggakanPokok =
                resp.data.data.tunggakanPokok;
              this.dataForm.step3.tunggakanMargin =
                resp.data.data.tunggakanMargin;
              this.dataForm.step3.totalPeriod = resp.data.data.totalPeriod;
              this.table.data.step3 = resp.data.data.detailInstallment;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.property.listElement.step3.message =
                "Belum Ada Data Yang Di Proses";
            } else {
              this.property.listElement.step3.message = resp.data.message;
            }
          } catch (error) {
            setTimeout(() => {
              this.handleErrorLoanInstallment(error);
            }, timeout);
          } finally {
            setTimeout(() => {
              this.property.listElement.step3.downloading = false;
            }, timeout);
          }
        }
      }
    },
    resetFormRequestResponseLoanInstallment() {
      this.dataForm.step3.contractNumber = "";
      this.dataForm.step3.cifIdName = "";
      this.dataForm.step3.realitationDate = "";
      this.dataForm.step3.dueDate = "";
      this.dataForm.step3.nominalLoan = "";
      this.dataForm.step3.totalInstallment = "";
      this.dataForm.step3.totalMargin = "";
      this.dataForm.step3.totalPeriod = "";
      this.dataForm.step3.loanData.timePeriod = "";
      this.dataForm.step3.loanData.endDate = "";
      this.dataForm.step3.loanData.nominalLoan = "";
      this.table.data.step3 = [];
      this.dataForm.step3.principal = 0;
      this.dataForm.step3.margin = 0;
      this.dataForm.step3.loanAkadTypeAcronym = "";
    },
    async getLoanAccountByIdInLoanInstallment() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "transaction-loan/findById/" +
              this.dataForm.step3.pilihNomorKontrak,
          });

          if (resp.data.code === "SUCCESS") {
            this.dataForm.step3.loanData.timePeriod = resp.data.data.timePeriod;
            this.dataForm.step3.loanData.endDate = resp.data.data.endDate;
            this.dataForm.step3.loanData.nominalLoan =
              resp.data.data.nominalLoan;
            this.dataForm.step3.loanData.loanAkadTypeAcronym = resp.data.data
              .mloan
              ? resp.data.data.mloan.rloanAkadType
                ? resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym
                : ""
              : "";
            this.dataForm.step3.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step3.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step3.createdDate = resp.data.data.createdDate;
            this.dataForm.step3.updatedDate = resp.data.data.updatedDate;
            this.dataForm.step3.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step3.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step3.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.step3.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step3.authorizedByUserName =
              resp.data.data.authorizedByUserName;

            if (this.table.data.step3.length > 0) {
              this.dataForm.step3.principal =
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
                  ? resp.data.data.nominalPurchase
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                      this.fetchAppSession("@vue-session/config-bundle")
                        .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
                    resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                      this.fetchAppSession("@vue-session/config-bundle")
                        .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
                  ? resp.data.data.participateBank
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                    this.fetchAppSession("@vue-session/config-bundle")
                      .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH
                  ? resp.data.data.nominalLoan
                  : 0;
              this.dataForm.step3.margin =
                resp.data.data.mloan.rloanMarginCalculate
                  .loanMarginCalculateId === "1"
                  ? resp.data.data.rateFlat
                  : resp.data.data.rateEffective;
            }
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    handleErrorLoanInstallment(error) {
      console.log(error.response);
      this.table.data.step3 = [];
      this.property.listElement.step3.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async processGenerateLoanInstallment() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.processLoanInstallment.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/generate/" +
                  this.dataForm.step3.pilihNomorKontrak,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.changeOptionSelectContractNumberLoanInstallment();
                  }, 500);
                } else if (resp.data.code === "DATA_ALREADY_EXIST") {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `Nomor Kontrak Sudah Pernah Di Proses`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.processLoanInstallment.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    printLoanInstallment() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.step3.map((index) => {
        headerFinal.push(index.label);
      });
      if (headerFinal.length > 7) {
        headerFinal.length = 7;
      }
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.step3.map((index) => {
        finalDataTable.push([
          numbering,
          `${
            index.principalRemain
              ? index.principalRemain
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.principal
              ? index.principal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.margin
              ? index.margin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.installment
              ? index.installment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.rloanPaymentStatus
              ? index.rloanPaymentStatus.paymentStatusName
              : ""
          }`,
          `${index.rstatus ? index.rstatus.statusName : ""}`,
        ]);
        numbering = numbering + 1;
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text("Data Angsuran Pembiayaan", pageWidth / 2, 0.8, "center");
      doc
        .setFontSize(8)
        .text(
          `Nomor Kontrak  :   ${this.dataForm.step3.contractNumber}`,
          0.55,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Nama                :   ${this.dataForm.step3.cifIdName}`,
          0.55,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Jangka Waktu   :  ${this.dataForm.step3.totalPeriod} Bulan`,
          0.55,
          1.7
        );
      doc
        .setFontSize(8)
        .text(`Jatuh Tempo    :   ${this.dataForm.step3.dueDate} `, 0.55, 1.9);
      doc
        .setFontSize(8)
        .text(
          `Nominal Pokok  :   ${this.dataForm.step3.principal
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
          pageWidth / 2 + 1,
          1.3
        );
      doc
        .setFontSize(8)
        .text(
          `Total Margin      :   ${this.dataForm.step3.margin}`,
          pageWidth / 2 + 1,
          1.5
        );
      doc
        .setFontSize(8)
        .text(
          `Total Angsuran  :   ${
            this.dataForm.step3.totalInstallment
              ? this.dataForm.step3.totalInstallment
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          1.7
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          7: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          8: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 7,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.3 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    closeModalUpdateMarginLoanInstallment() {
      this.property.modal.step3.showModalEditMarginInstallment = false;
      this.dataForm.step3.updateMargin.newMargin = 0;
      this.dataForm.step3.updateMargin.newPrincipal = 0;
      this.dataForm.step3.updateMargin.loanInstallmentId = "";
    },
    async editMarginLoanInstallment() {
      const payload = {
        loanInstallmentId: this.dataForm.step3.updateMargin.loanInstallmentId,
        newMargin: this.dataForm.step3.updateMargin.newMargin,
        newPrincipal: this.dataForm.step3.updateMargin.newPrincipal,
      };

      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.updateMargin.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "loan-installment/update-margin",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.closeModalUpdateMarginLoanInstallment();
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.changeOptionSelectContractNumberLoanInstallment();
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.updateMargin.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    showModalUpdateMarginLoanInstallment(props) {
      this.dataForm.step3.updateMargin.newMargin = 0;
      this.dataForm.step3.updateMargin.newPrincipal = 0;
      this.dataForm.step3.updateMargin.loanInstallmentId = "";
      this.property.modal.step3.showModalEditMarginInstallment = true;
      this.dataForm.step3.updateMargin.newMargin = props.row.margin;
      this.dataForm.step3.updateMargin.newPrincipal = props.row.principal;
      this.dataForm.step3.updateMargin.loanInstallmentId =
        props.row.loanInstallmentId;
    },
  },
  mounted() {},
};
