import EditLoanAccount from "./loan-account";
import EditLoanBiCode from "./loan-bi-code";
import EditLoanCollateral from "./loan-collateral";
import EditLoanDocumentChecklist from "./loan-document-checklist";
import EditLoanInstallment from "./loan-installment";
import Mixins from "../Mixins.js";

export default {
  name: "EditTLoanAccount",
  mixins: [Mixins],
  components: {
    EditLoanAccount,
    EditLoanBiCode,
    EditLoanCollateral,
    EditLoanDocumentChecklist,
    EditLoanInstallment,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_LOAN_ACCOUT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/loan-account");
    },
  },
};
