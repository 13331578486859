import Moment from "moment";
import Mixins from "../../Mixins.js";

export default {
  name: "EditLoanAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      identifier: {
        loanAccountNumber: "",
        loanAccountParentId: "",
        sp3: {
          sp3Date: "",
          sp3Subject: "",
          sp3DocNumber: "",
          sp3Id: "",
        },
        cif: {
          cifMobilePhoneNumber: "",
          cifIdName: "",
          cifId: "",
        },
      },
      property: {
        modal: {
          step2: {
            showModalEditSubLoanAccount: false,
          },
        },
        listElement: {
          step2: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
        animation: {
          step1: {
            editLoanAccount: {
              isLoading: false,
            },
          },
          step2: {
            addSubLoanAccount: {
              isLoading: false,
            },
            editSubLoanAccount: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step1: {
          rateFlatMount: 0,
          statusId: "",
          insuranceAccountNumber: "",
          notaryPublicAccountNumber: "",
          referalOfficeId: "",
          cifNumber: "",
          cifCategoryName: "",
          cifIdName: "",
          officeName: "",
          isLineFacility: false,
          loanAccountNumber: "",
          loanId: "",
          akadNumber: "",
          akadDate: "",
          startDate: "",
          timePeriod: 0,
          billDay: 0,
          isAutodebt: false,
          rateMargin: 0,
          collateralSharedPercent: "",
          gracePeriode: 0,
          graceReason: "",
          accountSection: 0,
          endDate: "",
          nominalLoan: "",
          participateBank: "",
          participateCif: "",
          profitProjection: "",
          profitShareBank: "",
          rateFlat: "",
          rateEffective: "",
          margin: false,
          purposeLoan: "",
          isSmsNotification: false,
          isNeedCollateral: false,
          nominalCollateral: "",
          isRelated: false,
          ujrahDropGroupSub: "",
          ujrahDropGroup: "",
          savingAccountNumberDro: "",
          savingAccountNumberInstallment: "",
          feeAdmin: "",
          feeMaterai: "",
          feeNotaris: "",
          feeInsuranceLife: "",
          feeInsuranceLoss: "",
          feeInsuranceVehicle: "",
          blockedFund: "",
          feeTotal: "",
          cifMobilePhoneNumber: "",
          sp3Id: "",
          loanCategoryId: "",
          loanCollateralId: "",
          loanGroupId: "",
          loanGroupParentId: "",
          loanAccountParentId: null,
          nominalPurchase: 0,
          rateMargin: 0,
          nominalMargin: 0,
          totalMargin: 0,
          totalSellingPrice: 0,
          nominalInstallment: 0,
          totalParticipate: 0,
          isProfitShareBankPercentage: false,
          profitShareBankProjection: 0,
          journalRecord: {
            productCode: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN,
            journalRecordDetails: [],
          },
          loanAkadTypeAcronym: "",
          akadNumberNotaril: "",
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
          accountOfficerCollectorId: "",
          accountOfficerHandleId: "",
          accountOfficerPromoId: "",
        },
        step2: {
          add: {
            insuranceAccountNumber: "",
            notaryPublicAccountNumber: "",
            referalOfficeId: "",
            akadNumber: "",
            loanAccountNumber: "",
            loanAccountId: "",
            akadDate: "",
            startDate: "",
            endDate: "",
            timePeriod: 0,
            billDay: 0,
            isAutodebt: false,
            nominalLoan: 0,
            rateMargin: 0,
            nominalMargin: 0,
            gracePeriode: 0,
            graceReason: "",
            nominalTotal: 0,
            rateFlat: 0,
            rateEffective: 0,
            nominalInstallment: 0,
            purposeLoan: "",
            isSmsNotification: false,
            isNeedCollateral: false,
            nominalCollateral: 0,
            collateralSharedPercent: 0,
            ujrahDropGroup: 0,
            ujrahDropGroupSub: 0,
            accountSection: 0,
            feeAdmin: 0,
            feeMaterai: 0,
            feeNotaris: 0,
            feeInsuranceLife: 0,
            feeInsuranceLoss: 0,
            feeInsuranceVehicle: 0,
            blockedFund: 0,
            feeTotal: 0,
            participateBank: 0,
            participateCif: 0,
            profitShareBank: 0,
            profitProjection: 0,
            isRelated: false,
            sp3Id: "",
            margin: false,
            loanId: "",
            savingAccountNumberDro: "",
            savingAccountNumberInstallment: "",
            loanCategoryId: "",
            loanCollateralId: "",
            loanGroupId: "",
            loanGroupParentId: "",
            accountOfficerCollectorId: "",
            accountOfficerHandleId: "",
            accountOfficerPromoId: "",
            loanAccountParentId: "",
            isLineFacility: false,
            nominalPurchase: 0,
            totalPariticipate: 0,
            nominalMargin: 0,
            totalMargin: 0,
            totalSellingPrice: 0,
            totalParticipate: 0,
            isProfitShareBankPercentage: false,
            profitShareBankProjection: 0,
            journalRecord: {
              productCode: "",
              transactionCode: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_TRX_CODE_DROPPING_LOAN,
              journalRecordDetails: [],
            },
            loanAkadTypeAcronym: "",
            akadNumberNotaril: "",
          },
          edit: {
            insuranceAccountNumber: "",
            notaryPublicAccountNumber: "",
            referalOfficeId: "",
            akadNumber: "",
            loanAccountNumber: "",
            loanAccountId: "",
            akadDate: "",
            startDate: "",
            endDate: "",
            timePeriod: 0,
            billDay: 0,
            isAutodebt: false,
            nominalLoan: 0,
            rateMargin: 0,
            gracePeriode: 0,
            graceReason: "",
            nominalMargin: 0,
            nominalTotal: 0,
            rateFlat: 0,
            rateEffective: 0,
            nominalInstallment: 0,
            purposeLoan: "",
            isSmsNotification: false,
            isNeedCollateral: false,
            nominalCollateral: 0,
            collateralSharedPercent: 0,
            ujrahDropGroup: 0,
            ujrahDropGroupSub: 0,
            accountSection: 0,
            feeAdmin: 0,
            feeMaterai: 0,
            feeNotaris: 0,
            feeInsuranceLife: 0,
            feeInsuranceLoss: 0,
            feeInsuranceVehicle: 0,
            blockedFund: 0,
            feeTotal: 0,
            participateBank: 0,
            participateCif: 0,
            profitShareBank: 0,
            profitProjection: 0,
            isRelated: false,
            sp3Id: "",
            margin: false,
            loanId: "",
            savingAccountNumberDro: "",
            savingAccountNumberInstallment: "",
            loanCategoryId: "",
            loanCollateralId: "",
            loanGroupId: "",
            loanGroupParentId: "",
            accountOfficerCollectorId: "",
            accountOfficerHandleId: "",
            accountOfficerPromoId: "",
            loanAccountParentId: "",
            isLineFacility: false,
            cifMobilePhoneNumber: "",
            nominalPurchase: 0,
            totalParticipate: 0,
            totalSellingPrice: 0,
            isProfitShareBankPercentage: false,
            profitShareBankProjection: 0,
            totalMargin: 0,
            journalRecord: {
              productCode: "",
              transactionCode: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_TRX_CODE_DROPPING_LOAN,
              journalRecordDetails: [],
            },
            loanAkadTypeAcronym: "",
            akadNumberNotaril: "",
            createdByUserCode: "",
            createdByUserName: "",
            createdDate: "",
            updatedDate: "",
            updatedByUserCode: "",
            updatedByUserName: "",
            authorizedDate: "",
            authorizedByUserCode: "",
            authorizedByUserName: "",
          },
        },
      },
      options: {
        step1: {
          titipanAsuransi: [],
          accountNotaris: [],
          referalOffice: [],
          produkPembiayaan: [],
          produkPembiayaanByAkad: [],
          collector: [],
          accountOfficer: [],
          savingAccount: [],
          rekeningDroping: [],
          rekeningAngsuran: [],
          grupPendanaan: [],
          subLoanGroup: [],
          loanAkadType: [],
          loanCollateral: [],
          savingAccountByCifId: [],
          loanGroupCategory: [],
        },
        step2: {
          referalOffice: [],
          produkPembiayaan: [],
          grupPendanaan: [],
          subLoanGroup: [],
          ujrahDropingSubGrup: [],
          rekeningDroping: [],
          rekeningAngsuran: [],
          collector: [],
          loanGroupCategory: [],
          accountOfficer: [],
          produkPembiayaanByAkad: {
            add: [],
            edit: [],
          },
          loanAkadType: [],
          loanCollateral: [],
          savingAccountByCifId: [],
        },
      },
      table: {
        header: {},
        data: {
          step2: [],
        },
      },
      show: {
        position: {
          order: 0,
          name: "",
        },
        history: [],
        steps: [
          { name: "step1", position: 1 },
          { name: "step2", position: 2 },
          { name: "step3", position: 3 },
          { name: "step4", position: 4 },
          { name: "step5", position: 5 },
          { name: "step6", position: 6 },
        ],
        form: [
          { name: "step1", active: false },
          { name: "step2", active: false },
          { name: "step3", active: false },
          { name: "step4", active: false },
          { name: "step5", active: false },
          { name: "step6", active: false },
        ],
      },
    };
  },
  methods: {
    defaultSetupStepper() {
      const index = this.show.form[0];
      index.active = true;
      const findingPostionByName = this.show.steps.find(
        (item) => item.name === index.name
      );
      this.show.position.name = findingPostionByName.name;
      this.show.position.order = findingPostionByName.position;
      this.show.history.push({ name: findingPostionByName.name });
    },
    next() {
      const findPositions = this.show.steps.find(
        (i) => i.position === this.show.position.order + 1
      );
      this.show.form.map((i) => {
        if (i.name === findPositions.name) {
          i.active = true;
        }
      });
      this.show.form.map((item) => {
        if (item.name === this.show.position.name) {
          item.active = false;
        }
      });
      this.show.position.name = findPositions.name;
      this.show.position.order = findPositions.position;
      this.show.history.push({ name: findPositions.name });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    back() {
      if (this.show.position.order === 1) {
        return;
      } else {
        this.show.form.map((i) => {
          if (i.name === this.show.history[this.show.history.length - 1].name) {
            i.active = false;
          }
        });
        this.show.history.pop();
        this.show.form.map((item) => {
          if (
            item.name === this.show.history[this.show.history.length - 1].name
          ) {
            item.active = true;
          }
        });
        var findingFromStep = this.show.steps.find(
          (i) => i.name === this.show.history[this.show.history.length - 1].name
        );

        this.show.position.name = findingFromStep.name;
        this.show.position.order = findingFromStep.position;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    globalDirectiveCheckLoanInstallmentHistory(props) {
      sessionStorage.setItem(
        "LOAN_ACCOUNT_NUMBER",
        props.row.loanAccountNumber
      );
      this.$router.push("/report/history-angsuran/detail");
    },
    getIdentityFromSession() {
      var params = this.$route.params.id;
      const getTokenFromSession = this.decryptBASE64(params);
      this.identity.loanAccountId = getTokenFromSession;
    },
    routeToPageListLoanAccount() {
      this.$router.push("/loan/loan-account");
    },
    async getLoanAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "transaction-loan/findById/" + this.identity.loanAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step1.statusId = resp.data.data.rstatus.statusId;
            this.dataForm.step1.insuranceAccountNumber =
              resp.data.data.insuranceAccountNumber;
            this.dataForm.step1.notaryPublicAccountNumber =
              resp.data.data.notaryPublicAccountNumber;
            this.dataForm.step1.akadNumberNotaril =
              resp.data.data.akadNumberNotaril;
            this.dataForm.step1.loanAkadTypeAcronym = resp.data.data.mloan
              ? resp.data.data.mloan.rloanAkadType
                ? resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym
                : ""
              : "";
            this.dataForm.step1.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step1.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step1.createdDate = resp.data.data.createdDate;
            this.dataForm.step1.updatedDate = resp.data.data.updatedDate;
            this.dataForm.step1.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step1.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step1.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.step1.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step1.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.dataForm.step1.referalOfficeId =
              resp.data.data.referalOfficeId;
            this.changeSelectTypeAkadLoanAccount();
            this.dataForm.step1.cifNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifNumber
                : ""
              : "";
            this.dataForm.step1.cifCategoryName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                  ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                      .cifCategoryName
                  : ""
                : ""
              : "";

            this.dataForm.step1.cifIdName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifIdName
                : ""
              : "";
            this.dataForm.step1.officeName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.moffice
                  ? resp.data.data.tloanSp3.mcif.moffice.officeName
                  : ""
                : ""
              : "";
            this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step1.sp3Id = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.loanSp3Id
              : "";
            this.identifier.cif.cifId = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifId
                : ""
              : "";
            this.identifier.cif.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.identifier.sp3.sp3Date = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Date
              : "";
            this.identifier.sp3.sp3Subject = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Subject
              : "";
            this.identifier.sp3.sp3Id = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.loanSp3Id
              : "";
            this.dataForm.step1.isLineFacility = resp.data.data.isLineFacility;
            this.dataForm.step1.gracePeriode = resp.data.data.gracePeriode;
            this.dataForm.step1.graceReason = resp.data.data.graceReason;
            this.dataForm.step1.akadNumber = resp.data.data.akadNumber;
            this.dataForm.step1.akadDate = resp.data.data.akadDate;
            this.dataForm.step1.startDate = resp.data.data.startDate;
            this.dataForm.step1.timePeriod = resp.data.data.timePeriod;
            this.dataForm.step1.endDate = resp.data.data.endDate;
            this.dataForm.step1.participateBank =
              resp.data.data.participateBank;
            this.dataForm.step1.participateCif = resp.data.data.participateCif;
            this.dataForm.step1.profitProjection =
              resp.data.data.profitProjection;
            this.dataForm.step1.profitShareBank =
              resp.data.data.profitShareBank;
            this.dataForm.step1.profitShareBankProjection =
              resp.data.data.profitShareBank;
            this.dataForm.step1.rateFlat = resp.data.data.rateFlat;
            this.dataForm.step1.rateEffective = resp.data.data.rateEffective;
            this.dataForm.step1.margin =
              resp.data.data.rLoanMarginCalculate.loanMarginCalculateId === "1"
                ? true
                : false;
            this.dataForm.step1.purposeLoan = resp.data.data.purposeLoan;
            this.dataForm.step1.isSmsNotification =
              resp.data.data.isSmsNotification;
            this.dataForm.step1.isNeedCollateral =
              resp.data.data.isNeedCollateral;
            this.dataForm.step1.loanGroupParentId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupParentId
                ? resp.data.data.mloanGroup.loanGroupParentId.loanGroupId
                : ""
              : "";

            this.dataForm.step1.loanGroupId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupId
              : "";
            this.changeLoanGroupLoanAccount(this.dataForm.step1.loanGroupId);
            this.dataForm.step1.rateFlatMount = resp.data.data.rateFlat / 12;
            this.dataForm.step1.nominalLoan =
              resp.data.data.nominalLoan + resp.data.data.profitShareBank;
            this.dataForm.step1.isRelated = resp.data.data.isRelated;
            this.dataForm.step1.ujrahDropGroup = resp.data.data.ujrahDropGroup;
            this.dataForm.step1.ujrahDropGroupSub =
              resp.data.data.ujrahDropGroupSub;
            this.dataForm.step1.savingAccountNumberDro = resp.data.data
              .tsavingAccountDroping
              ? resp.data.data.tsavingAccountDroping.accountNumber
              : "";
            this.dataForm.step1.savingAccountNumberInstallment = resp.data.data
              .tsavingAccountInstallment
              ? resp.data.data.tsavingAccountInstallment.accountNumber
              : "";
            this.dataForm.step1.accountOfficerCollectorId =
              resp.data.data.accountOfficerCollectorId;
            this.dataForm.step1.feeAdmin = resp.data.data.feeAdmin;
            this.dataForm.step1.feeMaterai = resp.data.data.feeMaterai;
            this.dataForm.step1.feeNotaris = resp.data.data.feeNotaris;
            this.dataForm.step1.feeInsuranceLife =
              resp.data.data.feeInsuranceLife;
            this.dataForm.step1.feeInsuranceLoss =
              resp.data.data.feeInsuranceLoss;
            this.dataForm.step1.feeInsuranceVehicle =
              resp.data.data.feeInsuranceVehicle;
            this.dataForm.step1.blockedFund = resp.data.data.blockedFund;
            this.dataForm.step1.feeTotal = resp.data.data.feeTotal;
            this.dataForm.step1.billDay = resp.data.data.billDay;
            this.dataForm.step1.nominalInstallment =
              resp.data.data.nominalInstallment;
            this.dataForm.step1.nominalCollateral =
              resp.data.data.nominalCollateral;
            this.dataForm.step1.accountOfficerHandleId =
              resp.data.data.accountOfficerHandleId;
            this.dataForm.step1.accountOfficerPromoId =
              resp.data.data.accountOfficerPromoId;
            this.dataForm.step1.loanCollateralId = resp.data.data
              .mloanCollateral
              ? resp.data.data.mloanCollateral.loanCollateralId
              : "";
            this.dataForm.step1.loanCategoryId = resp.data.data
              .rloanGroupCategory
              ? resp.data.data.rloanGroupCategory.loanGroupCategoryId
              : "";
            this.dataForm.step1.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.identifier.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.identifier.loanAccountParentId = resp.data.data.loanAccountId;
            this.identifier.sp3.sp3DocNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3DocNumber
              : "";
            this.identifier.cif.cifIdName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifIdName
                : ""
              : "";
            this.dataForm.step1.loanId = resp.data.data.mloan
              ? resp.data.data.mloan.loanId
              : "";
            this.dataForm.step1.nominalPurchase =
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
                ? resp.data.data.nominalPurchase
                : 0;
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ) {
              this.dataForm.step1.rateMargin = resp.data.data.rateFlat;
              this.calculateRateLoanAccount("get_by_id");
            }
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ) {
              this.dataForm.step1.totalParticipate =
                this.dataForm.step1.participateBank +
                this.dataForm.step1.participateCif;
            }
            this.$store.dispatch(
              "SET_IDENTIFIER_LOAN_ACCOUNT_ID",
              resp.data.data.loanAccountId
            );
            this.MIXINS_METHOD_getRefferenceLoanAccount();
            this.getSavingAccountByCifId();
            this.getRefferenceLoanChartOfAccountByLoanIdLoanAccount(
              resp.data.data.journalRecord
            );
            this.getSubLoanAccount();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListLoanAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListLoanAccount(),
          });
        }
      }
    },
    changeSelectTypeAkadLoanAccount() {
      this.resetFormNominalPembiayaanLoanAccount();
      const filterAkad = this.options.step1.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym === this.dataForm.step1.loanAkadTypeAcronym
      );
      this.dataForm.step1.loanId = "";
      this.options.step1.produkPembiayaanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    calculateRateLoanAccount(triggerby) {
      this.dataForm.step1.rateFlat = this.dataForm.step1.rateMargin;
      this.dataForm.step1.rateFlatMount = this.dataForm.step1.rateFlat / 12;
      if (triggerby === "manual_input") {
        if (
          this.dataForm.step1.margin === false &&
          this.dataForm.step1.nominalPurchase &&
          this.dataForm.step1.rateMargin
        ) {
          const payload = {
            rateFlat: this.dataForm.step1.rateMargin,
            principal: this.dataForm.step1.nominalPurchase,
          };
          this.convertToEffectiveLoanAccount(
            payload.rateFlat,
            payload.principal
          );
        }
      }
      this.dataForm.step1.totalMargin = this.dataForm.step1.timePeriod
        ? (this.dataForm.step1.rateMargin / 12) * this.dataForm.step1.timePeriod
        : 0;
      this.dataForm.step1.nominalMargin = this.dataForm.step1.totalMargin
        ? (this.dataForm.step1.totalMargin *
            this.dataForm.step1.nominalPurchase) /
          100
        : 0;
      this.dataForm.step1.totalSellingPrice = this.dataForm.step1.nominalMargin
        ? this.dataForm.step1.nominalMargin +
          this.dataForm.step1.nominalPurchase
        : 0;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.totalSellingPrice;
      this.dataForm.step1.nominalInstallment =
        this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identifier.cif.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.accountNumber;
            this.options.step1.savingAccountByCifId.push({ text, value });
            this.options.step2.savingAccountByCifId.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async getRefferenceLoanChartOfAccountByLoanIdLoanAccount(
      currentJournalRecord
    ) {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          Promise.all(
            resp.data.data.map((i, index) => {
              console.log(index, i);
              const productLedgerId = i.loanChartOfAccountId;
              const chartOfAccountId = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountId
                : "";
              const label = i.productLedger
                ? i.productLedger.productLedgerName
                : "";
              const positionMutation = i.mutationPosition;
              const labelInputComponent = i.chartOfAccount
                ? i.chartOfAccount.description
                : "";
              const pairPositionMutation = i.pairMutationPosition;
              const pairChartOfAccountId = i.pairChartOfAccount
                ? i.pairChartOfAccount.chartOfAccountId
                : "";
              const chartOfAccountCode = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountCode
                : "";

              const pairChartOfAccountCode = i.pairChartOfAccount
                ? i.pairChartOfAccount.pairChartOfAccountCode
                : "";

              const pairType = i.pairType;
              const amount = 0;
              this.dataForm.step1.journalRecord.journalRecordDetails.push({
                productLedgerId,
                chartOfAccountId,
                pairChartOfAccountId,
                labelInputComponent,
                label,
                positionMutation,
                pairPositionMutation,
                chartOfAccountCode,
                pairChartOfAccountCode,
                pairType,
                amount,
                index: index,
              });
            })
          ).then(() => {
            if (currentJournalRecord) {
              this.insertJournalRecordLoanAccount(currentJournalRecord);
            }
          });
        }
      } catch (error) {}
    },
    async getSubLoanAccount() {
      this.table.data.step2 = [];
      this.property.listElement.step2.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.identifier.loanAccountNumber,
            cifIdName: "",
            isSubContract: true,
            page: 0,
            size: this.property.listElement.step2.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step2 = resp.data.data.pageResponse.content;
            this.property.listElement.step2.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.property.listElement.step2.message = resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSubLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step2.downloading = false;
        }, timeout);
      }
    },
    resetFormNominalPembiayaanLoanAccount() {
      this.dataForm.step1.participateBank = 0;
      this.dataForm.step1.participateCif = 0;
      this.dataForm.step1.rateFlat = 0;
      this.dataForm.step1.rateEffective = 0;
      this.dataForm.step1.profitShareBank = 0;
      this.dataForm.step1.nominalPurchase = 0;
      this.dataForm.step1.nominalLoan = 0;
      this.dataForm.step1.nominalInstallment = 0;
      this.dataForm.step1.rateMargin = 0;
      this.dataForm.step1.totalMargin = 0;
      this.dataForm.step1.totalSellingPrice = 0;
      this.dataForm.step1.nominalMargin = 0;
      this.dataForm.step1.totalParticipate = 0;
      this.dataForm.step1.profitProjection = 0;
      this.dataForm.step1.profitShareBankProjection = 0;
      this.dataForm.step1.isProfitShareBankPercentage = false;
    },
    async rejectAuthorizeLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      }
      this.$buefy.dialog.prompt({
        message: `Reject Authorisasi Kontrak <b>${props.row.loanAccountNumber}</b> ?`,
        inputAttrs: {
          placeholder: "Deskripsi",
        },
        type: "is-link",
        confirmText: "Konfirmasi",
        cancelText: "Batal",
        closeOnConfirm: false,
        trapFocus: true,
        onConfirm: async (value, { close }) => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/reject/" + props.row.loanAccountId,
              payload: {
                description: value,
              },
            });
            if (resp.data.code === "SUCCESS") {
              close();
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.getSubLoanAccount();
                this.MIXINS_METHOD_getRefferenceLoanAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    async convertToEffectiveLoanAccount(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step1.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step1.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    insertJournalRecordLoanAccount(currentJournalRecord) {
      this.dataForm.step1.journalRecord.journalRecordDetails.map((index) => {
        currentJournalRecord.journalRecordDetails.map((i) => {
          if (i.chartOfAccountId === index.chartOfAccountId) {
            index.amount = i.amount;
          }
        });
      });
    },
    handleErrorSubLoanAccount(error) {
      console.log(error.response);
      this.table.data.step2 = [];
      this.property.listElement.step2.rows = 0;
      this.property.listElement.step2.currentPage = 1;
      this.property.listElement.step2.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    changeProdukPembiayaanLoanAccount() {
      this.getLoanChartOfAccountByLoanIdLoanAccount();
      this.options.step1.produkPembiayaan.map((i) => {
        if (i.value === this.dataForm.step1.loanId) {
          this.dataForm.step1.journalRecord.productCode = i.loanCode;
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step1.margin = true;
              this.dataForm.step1.rateEffective = 0;
              break;
            case "2":
              this.dataForm.step1.margin = false;
              this.dataForm.step1.rateFlat = 0;
              break;
            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccount("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    async getLoanChartOfAccountByLoanIdLoanAccount() {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const pairType = i.pairType;
            const amount = 0;
            this.dataForm.step1.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              pairChartOfAccountId,
              label,
              positionMutation,
              labelInputComponent,
              pairPositionMutation,
              chartOfAccountCode,
              pairChartOfAccountCode,
              pairType,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    calculateRateEffectiveAnnuallyLoanAccount() {
      if (
        this.dataForm.step1.profitShareBankProjection === 0 ||
        this.dataForm.step1.participateBank === 0 ||
        this.dataForm.step1.timePeriod === 0
      ) {
        this.dataForm.step1.rateEffective = 0;
        this.dataForm.step1.rateFlat = 0;
        return;
      }
      const calculateRate =
        (this.dataForm.step1.profitShareBankProjection /
          this.dataForm.step1.participateBank) *
        100;
      if (this.dataForm.step1.margin === false) {
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step1.timePeriod) * 12,
          principal: this.dataForm.step1.participateBank,
        };
        this.convertToEffectiveLoanAccount(payload.rateFlat, payload.principal);
      }
      this.dataForm.step1.rateFlat =
        (calculateRate / this.dataForm.step1.timePeriod) * 12;
      this.dataForm.step1.rateFlatMount = this.dataForm.step1.rateFlat / 12;
    },
    calculateProyeksiAngsuranLoanAccount() {
      if (
        this.dataForm.step1.nominalLoan !== 0 &&
        this.dataForm.step1.timePeriod !== 0
      ) {
        this.dataForm.step1.nominalInstallment =
          this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
        return;
      }
      this.dataForm.step1.nominalInstallment = 0;
    },
    changeTipeBagiHasilBankLoanAccount() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitShareBank /
                this.dataForm.step1.profitProjection) *
              100;
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitProjectionLoanAccount() {
      if (this.dataForm.step1.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentLoanAccount();
        this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
        this.calculateProyeksiAngsuranLoanAccount();
      }
      this.calculateRateEffectiveAnnuallyLoanAccount();
    },
    changeProfitShareBankPercentLoanAccount() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step1.profitShareBankProjection =
            (this.dataForm.step1.profitProjection *
              this.dataForm.step1.profitShareBank) /
            100;
          break;
        case false:
          this.dataForm.step1.profitShareBankProjection = this.dataForm.step1.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyLoanAccount();
      this.dataForm.step1.nominalLoan =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.profitShareBankProjection;
      this.calculateProyeksiAngsuranLoanAccount();
    },
    // changeProfitShareBankPercentLoanAccount() {
    //   switch (this.dataForm.step1.isProfitShareBankPercentage) {
    //     case true:
    //       this.dataForm.step1.profitShareBankProjection =
    //         (this.dataForm.step1.profitProjection *
    //           this.dataForm.step1.profitShareBank) /
    //         100;

    //       break;
    //     case false:
    //       this.dataForm.step1.profitShareBankProjection = this.dataForm.step1.profitShareBank;
    //       break;

    //     default:
    //       break;
    //   }
    //   this.calculateRateEffectiveAnnuallyLoanAccount();
    //   this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
    //   this.calculateProyeksiAngsuranLoanAccount();
    // },
    async getReferenceAccountOfficer() {
      // this.$store
      //   .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
      //     url: "v2/user/active-user",
      //     params: {
      //       page: 0,
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.data.code === "SUCCESS") {
      //       resp.data.data.content.map((index) => {
      //         const text = `${index.profileSurename} - ${index.userNik}`;
      //         const value = index.userId;
      //         this.options.step1.accountOfficer.push({ text, value });
      //         this.options.step2.accountOfficer.push({ text, value });
      //       });
      //     }
      //   });
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.step1.accountOfficer.push({ text, value });
            this.options.step2.accountOfficer.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeAcronym;
            this.options.step1.loanAkadType.push({
              text,
              value,
            });
            this.options.step2.loanAkadType.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    calculateParticipateLoanAccount(type) {
      this.dataForm.step1.totalParticipate =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.participateCif;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    getRefferenceLoanCollateral() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan-collateral",
          params: {
            collateralCategoryId: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            console.log(resp);
            resp.data.data.content.map((index) => {
              const text = index.description;
              const value = index.loanCollateralId;
              this.options.step1.loanCollateral.push({
                text,
                value,
              });
              this.options.step2.loanCollateral.push({
                text,
                value,
              });
            });
          }
        });
    },
    changeIsNeedCollateralLoanAccount(event) {
      if (event === false) {
        this.dataForm.step1.nominalCollateral = "";
      }
    },
    changeNominalMarginLoanAccount() {
      if (
        this.dataForm.step1.nominalMargin &&
        this.dataForm.step1.nominalPurchase &&
        this.dataForm.step1.timePeriod
      ) {
        this.dataForm.step1.totalMargin =
          (this.dataForm.step1.nominalMargin /
            this.dataForm.step1.nominalPurchase) *
          100;
        this.dataForm.step1.rateMargin =
          (this.dataForm.step1.totalMargin / this.dataForm.step1.timePeriod) *
          12;
      }
      this.calculateRateLoanAccount("manual_input");
    },
    confirmationModalAkadNumberLoanAccount() {
      this.$buefy.dialog.alert({
        title: "Data SP3",
        message: `
          <span><b>Tanggal SP3</b> : ${this.identifier.sp3.sp3Date}</span>
          <br />
          <span><b>Perihal</b> :  ${this.identifier.sp3.sp3Subject}</span>
          <br />
          <span><b>No. Sp3</b> : ${this.identifier.sp3.sp3DocNumber} </span>
          `,
        type: "is-info",
        confirmText: "Tutup",
        canCancel: ["outside"],
      });
    },
    async editLoanAccount() {
      let marginCalculateId = this.dataForm.step1.margin == true ? "1" : "2";
      const payload = {
        akadNumber: this.dataForm.step1.akadNumber,
        akadDate: this.dataForm.step1.akadDate,
        startDate: this.dataForm.step1.startDate,
        endDate: this.dataForm.step1.endDate,
        timePeriod: this.dataForm.step1.timePeriod,
        nominalLoan: this.dataForm.step1.nominalLoan,
        rateFlat: this.dataForm.step1.rateFlat,
        rateEffective: this.dataForm.step1.rateEffective,
        purposeLoan: this.dataForm.step1.purposeLoan,
        gracePeriode: this.dataForm.step1.gracePeriode,
        graceReason: this.dataForm.step1.graceReason,
        isSmsNotification: this.dataForm.step1.isSmsNotification,
        isNeedCollateral: this.dataForm.step1.isNeedCollateral,
        ujrahDropGroup: this.dataForm.step1.ujrahDropGroup,
        ujrahDropGroupSub: this.dataForm.step1.ujrahDropGroupSub,
        feeAdmin: this.dataForm.step1.feeAdmin,
        feeMaterai: this.dataForm.step1.feeMaterai,
        feeNotaris: this.dataForm.step1.feeNotaris,
        feeInsuranceLife: this.dataForm.step1.feeInsuranceLife,
        feeInsuranceLoss: this.dataForm.step1.feeInsuranceLoss,
        feeInsuranceVehicle: this.dataForm.step1.feeInsuranceVehicle,
        blockedFund: this.dataForm.step1.blockedFund,
        feeTotal: this.dataForm.step1.feeTotal,
        accountOfficerCollectorId: this.dataForm.step1
          .accountOfficerCollectorId,
        participateBank: this.dataForm.step1.participateBank,
        participateCif: this.dataForm.step1.participateCif,
        profitShareBank: this.dataForm.step1.profitShareBank,
        profitProjection: this.dataForm.step1.profitProjection,
        isRelated: this.dataForm.step1.isRelated,
        isLineFacility: this.dataForm.step1.isLineFacility,
        loanId: this.dataForm.step1.loanId,
        savingAccountNumberDro: this.dataForm.step1.savingAccountNumberDro,
        savingAccountNumberInstallment: this.dataForm.step1
          .savingAccountNumberInstallment,
        loanGroupId: this.dataForm.step1.loanGroupId,
        accountOfficerHandleId: this.dataForm.step1.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.step1.accountOfficerPromoId,
        sp3Id: this.dataForm.step1.sp3Id,
        loanCollateralId: this.dataForm.step1.loanCollateralId,
        loanCategoryId: this.dataForm.step1.loanCategoryId,
        billDay: this.dataForm.step1.billDay,
        isAutodebt: this.dataForm.step1.isAutodebt,
        nominalInstallment: this.dataForm.step1.nominalInstallment,
        nominalCollateral: this.dataForm.step1.nominalCollateral,
        collateralSharedPercent: this.dataForm.step1.collateralSharedPercent,
        loanAccountParentId: this.dataForm.step1.loanAccountParentId,
        loanAccountNumber: this.dataForm.step1.loanAccountNumber,
        loanAccountId: this.identity.loanAccountId,
        nominalPurchase: this.dataForm.step1.nominalPurchase,
        isProfitShareBankPercentage: this.dataForm.step1
          .isProfitShareBankPercentage,
        journalRecord: this.dataForm.step1.journalRecord,
        akadNumberNotaril: this.dataForm.step1.akadNumberNotaril,
        referalOfficeId: this.dataForm.step1.referalOfficeId,
        notaryPublicAccountNumber: this.dataForm.step1
          .notaryPublicAccountNumber,
        insuranceAccountNumber: this.dataForm.step1.insuranceAccountNumber,
        marginCalculateId: marginCalculateId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step1.editLoanAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "transaction-loan/" + this.identity.loanAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step1.editLoanAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    getReferenceLoan() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan/simple-list",
          params: {
            loanName: "",
            loanCode: "",
            page: 0,
          },
        })
        .then((resp) => {
          Promise.all(
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              const loanCode = index.loanCode;
              this.options.step1.produkPembiayaan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeAcronym,
                loanCode,
              });
              this.options.step2.produkPembiayaan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeAcronym,
                loanCode,
              });
            })
          ).then(() => {
            this.getLoanAccountById();
          });
        });
    },
    async getReferenceCollector() {
      // this.$store
      //   .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
      //     url: "v2/user/active-user",
      //     params: {
      //       page: 0,
      //     },
      //   })
      //   .then((resp) => {
      //     resp.data.data.content.map((index) => {
      //       const text = `${index.profileSurename} - ${index.userNik}`;
      //       const value = index.userId;
      //       this.options.step1.collector.push({ text, value });
      //       this.options.step2.collector.push({ text, value });
      //     });
      //   });
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.step1.collector.push({ text, value });
            this.options.step2.collector.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeLoanGroupLoanAccount(loanGroupId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step1.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.subLoanGroup = [];
          this.dataForm.step1.loanGroupId = loanGroupId
            ? this.dataForm.step1.loanGroupId
            : "";
          resp.data.data.map((data) => {
            const text = `${data.groupCode} - ${data.groupName}`;
            const value = data.loanGroupId;
            this.options.step1.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeLoanGroupLoanAccountAddSubLoanAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step2.add.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step2.add.loanGroupId = "";
          this.options.step2.subLoanGroup = [];
          resp.data.data.map((data) => {
            const text = data.groupName;
            const value = data.loanGroupId;
            this.options.step2.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeLoanGroupLoanAccountEditSubLoanAccount(loanGroupId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step2.edit.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step2.edit.loanGroupId = loanGroupId
            ? this.dataForm.step2.edit.loanGroupId
            : "";
          this.options.step2.subLoanGroup = [];
          resp.data.data.map((data) => {
            const text = data.groupName;
            const value = data.loanGroupId;
            this.options.step2.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getReferenceLoanGroup() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "m-loan-group",
          params: {
            groupCode: "",
            groupName: "",
            accountNumber: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = `${i.groupCode} - ${i.groupName}`;
              const value = i.loanGroupId;
              this.options.step1.grupPendanaan.push({ text, value });
              this.options.step2.grupPendanaan.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanGroupCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-group-category",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const text = i.loanGroupCategoryName;
              const value = i.loanGroupCategoryId;
              this.options.step1.loanGroupCategory.push({ text, value });
              this.options.step2.loanGroupCategory.push({ text, value });
            });
          } else {
          }
        });
    },
    changeStartDateLoanAccount() {
      if (this.dataForm.step1.timePeriod) {
        this.changeTimePeriodLoanAccount();
      }
    },
    changeEndDateLoanAccount() {
      if (
        this.dataForm.step1.endDate !== "" &&
        this.dataForm.step1.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step1.endDate);
        var b = Moment(this.dataForm.step1.startDate);
        this.dataForm.step1.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateLoanAccount("manual_input");
        }
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyLoanAccount();
          this.calculateProyeksiAngsuranLoanAccount();
        }
      }
    },
    changeTimePeriodLoanAccount() {
      if (this.dataForm.step1.startDate !== "") {
        var periode =
          this.dataForm.step1.timePeriod === 0 ||
          this.dataForm.step1.timePeriod === ""
            ? 1
            : this.dataForm.step1.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step1.endDate = calculate;
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccount("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    calculateFeeLoanAccount() {
      this.dataForm.step1.feeTotal =
        (!this.dataForm.step1.feeAdmin ? 0 : this.dataForm.step1.feeAdmin) +
        (!this.dataForm.step1.feeMaterai ? 0 : this.dataForm.step1.feeMaterai) +
        (!this.dataForm.step1.feeNotaris ? 0 : this.dataForm.step1.feeNotaris) +
        (!this.dataForm.step1.feeInsuranceLife
          ? 0
          : this.dataForm.step1.feeInsuranceLife) +
        (!this.dataForm.step1.feeInsuranceLoss
          ? 0
          : this.dataForm.step1.feeInsuranceLoss) +
        (!this.dataForm.step1.feeInsuranceVehicle === ""
          ? 0
          : this.dataForm.step1.feeInsuranceVehicle);
    },
    changeProdukPembiayaanAddSubLoanAccount() {
      this.getLoanChartOfAccountByLoanIdAddSubLoanAccount();
      this.options.step2.produkPembiayaan.map((i) => {
        if (i.value === this.dataForm.step2.add.loanId) {
          this.dataForm.step2.add.journalRecord.productCode = i.loanCode;
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step2.add.margin = true;
              this.dataForm.step2.add.rateEffective = 0;
              break;
            case "2":
              this.dataForm.step2.add.margin = false;
              this.dataForm.step2.add.rateFlat = 0;
              break;

            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateSubLoanAccountAdd();
      }
      if (
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
        this.calculateProyeksiAngsuranAddSubLoanAccount();
      }
    },
    async getLoanChartOfAccountByLoanIdAddSubLoanAccount() {
      try {
        this.dataForm.step2.add.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step2.add.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const positionMutation = i.mutationPosition;
            const pairPositionMutation = i.pairMutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const pairType = i.pairType;
            const amount = 0;
            this.dataForm.step2.add.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              pairChartOfAccountId,
              label,
              positionMutation,
              labelInputComponent,
              pairPositionMutation,
              chartOfAccountCode,
              pairChartOfAccountCode,
              pairType,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    calculateRateSubLoanAccountAdd() {
      this.dataForm.step2.add.rateFlat = this.dataForm.step2.add.rateMargin;
      if (
        this.dataForm.step2.add.margin === false &&
        this.dataForm.step2.add.nominalPurchase &&
        this.dataForm.step2.add.rateMargin
      ) {
        const payload = {
          rateFlat: this.dataForm.step2.add.rateMargin,
          principal: this.dataForm.step2.add.nominalPurchase,
        };
        this.convertToEffectiveAddSubLoanAccount(
          payload.rateFlat,
          payload.principal
        );
      }
      this.dataForm.step2.add.totalMargin = this.dataForm.step2.add.timePeriod
        ? (this.dataForm.step2.add.rateMargin / 12) *
          this.dataForm.step2.add.timePeriod
        : 0;
      this.dataForm.step2.add.nominalMargin = this.dataForm.step2.add
        .totalMargin
        ? (this.dataForm.step2.add.totalMargin *
            this.dataForm.step2.add.nominalPurchase) /
          100
        : 0;
      this.dataForm.step2.add.totalSellingPrice = this.dataForm.step2.add
        .nominalMargin
        ? this.dataForm.step2.add.nominalMargin +
          this.dataForm.step2.add.nominalPurchase
        : 0;
      this.dataForm.step2.add.nominalLoan = this.dataForm.step2.add.totalSellingPrice;
      this.dataForm.step2.add.nominalInstallment =
        this.dataForm.step2.add.nominalLoan /
        this.dataForm.step2.add.timePeriod;
    },
    calculateRateEffectiveAnnuallyAddSubLoanAccount() {
      if (
        this.dataForm.step2.add.timePeriod === 0 ||
        this.dataForm.step2.add.participateBank === 0 ||
        this.dataForm.step2.add.profitShareBankProjection === 0
      ) {
        this.dataForm.step2.add.rateEffective = 0;
        this.dataForm.step2.add.rateFlat = 0;
        return;
      }
      const calculateRate =
        (this.dataForm.step2.add.profitShareBankProjection /
          this.dataForm.step2.add.participateBank) *
        100;
      if (this.dataForm.step2.add.margin === false) {
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step2.add.timePeriod) * 12,
          principal: this.dataForm.step2.add.participateBank,
        };
        this.convertToEffectiveAddSubLoanAccount(
          payload.rateFlat,
          payload.principal
        );
      }
      this.dataForm.step2.add.rateFlat =
        (calculateRate / this.dataForm.step2.add.timePeriod) * 12;
    },
    calculateProyeksiAngsuranAddSubLoanAccount() {
      if (
        this.dataForm.step2.add.nominalLoan !== 0 &&
        this.dataForm.step2.add.timePeriod !== 0
      ) {
        this.dataForm.step2.add.nominalInstallment =
          this.dataForm.step2.add.nominalLoan /
          this.dataForm.step2.add.timePeriod;
        return;
      }
      this.dataForm.step2.add.nominalInstallment = 0;
    },
    async convertToEffectiveAddSubLoanAccount(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step2.add.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step2.add.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    changeSelectTypeAkadAddSubLoanAccount() {
      this.resetFormNominalPembiayaanAddSubLoanAccount();
      const filterAkad = this.options.step2.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym ===
          this.dataForm.step2.add.loanAkadTypeAcronym
      );
      this.dataForm.step2.add.loanId = "";
      this.options.step2.produkPembiayaanByAkad.add = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    resetFormNominalPembiayaanAddSubLoanAccount() {
      this.dataForm.step2.add.participateBank = 0;
      this.dataForm.step2.add.participateCif = 0;
      this.dataForm.step2.add.rateFlat = 0;
      this.dataForm.step2.add.rateEffective = 0;
      this.dataForm.step2.add.profitShareBank = 0;
      this.dataForm.step2.add.profitProjection = 0;
      this.dataForm.step2.add.nominalPurchase = 0;
      this.dataForm.step2.add.nominalLoan = 0;
      this.dataForm.step2.add.nominalInstallment = 0;
      this.dataForm.step2.add.rateMargin = 0;
      this.dataForm.step2.add.totalMargin = 0;
      this.dataForm.step2.add.totalSellingPrice = 0;
      this.dataForm.step2.add.nominalMargin = 0;
      this.dataForm.step2.add.totalParticipate = 0;
      this.dataForm.step2.add.profitProjection = 0;
      this.dataForm.step2.add.profitShareBankProjection = 0;
      this.dataForm.step2.add.isProfitShareBankPercentage = false;
    },
    confirmationModalAkadNumberSubLoanAccount() {
      this.$buefy.dialog.alert({
        title: "Data SP3",
        message: `
          <span><b>Tanggal SP3</b> : ${this.identifier.sp3.sp3Date}</span>
          <br />
          <span><b>Perihal</b> :  ${this.identifier.sp3.sp3Subject}</span>
          <br />
          <span><b>No. Sp3</b> : ${this.identifier.sp3.sp3DocNumber} </span>
          `,
        type: "is-info",
        confirmText: "Tutup",
        canCancel: ["outside"],
      });
    },
    async addSubLoanAccount() {
      const payload = {
        akadDate: this.dataForm.step2.add.akadDate,
        startDate: this.dataForm.step2.add.startDate,
        endDate: this.dataForm.step2.add.endDate,
        timePeriod: this.dataForm.step2.add.timePeriod,
        nominalLoan: this.dataForm.step2.add.nominalLoan,
        rateFlat: this.dataForm.step2.add.rateFlat,
        rateEffective: this.dataForm.step2.add.rateEffective,
        purposeLoan: this.dataForm.step2.add.purposeLoan,
        isSmsNotification: this.dataForm.step2.add.isSmsNotification,
        isNeedCollateral: this.dataForm.step2.add.isNeedCollateral,
        isAutodebt: this.dataForm.step2.add.isAutodebt,
        gracePeriode: this.dataForm.step2.add.gracePeriode,
        graceReason: this.dataForm.step2.add.graceReason,
        billDay: this.dataForm.step2.add.billDay,
        nominalCollateral: this.dataForm.step2.add.nominalCollateral,
        ujrahDropGroup: this.dataForm.step2.add.ujrahDropGroup,
        ujrahDropGroupSub: this.dataForm.step2.add.ujrahDropGroupSub,
        feeAdmin: this.dataForm.step2.add.feeAdmin,
        feeMaterai: this.dataForm.step2.add.feeMaterai,
        feeNotaris: this.dataForm.step2.add.feeNotaris,
        feeInsuranceLife: this.dataForm.step2.add.feeInsuranceLife,
        feeInsuranceLoss: this.dataForm.step2.add.feeInsuranceLoss,
        feeInsuranceVehicle: this.dataForm.step2.add.feeInsuranceVehicle,
        blockedFund: this.dataForm.step2.add.blockedFund,
        feeTotal: this.dataForm.step2.add.feeTotal,
        accountOfficerCollectorId: this.dataForm.step2.add
          .accountOfficerCollectorId,
        participateBank: this.dataForm.step2.add.participateBank,
        participateCif: this.dataForm.step2.add.participateCif,
        profitShareBank: this.dataForm.step2.add.profitShareBank,
        profitProjection: this.dataForm.step2.add.profitProjection,
        isRelated: this.dataForm.step2.add.isRelated,
        loanId: this.dataForm.step2.add.loanId,
        savingAccountNumberDro: this.dataForm.step2.add.savingAccountNumberDro,
        savingAccountNumberInstallment: this.dataForm.step2.add
          .savingAccountNumberInstallment,
        accountOfficerHandleId: this.dataForm.step2.add.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.step2.add.accountOfficerPromoId,
        collateralSharedPercent: this.dataForm.step2.add
          .collateralSharedPercent,
        accountSection: this.dataForm.step2.add.accountSection,
        loanCollateralId: this.dataForm.step2.add.loanCollateralId,
        loanCategoryId: this.dataForm.step2.add.loanCategoryId,
        loanGroupId: this.dataForm.step2.add.loanGroupId,
        nominalMargin: this.dataForm.step2.add.nominalMargin,
        isLineFacility: this.dataForm.step2.add.isLineFacility,
        loanAccountParentId: this.identifier.loanAccountParentId,
        sp3Id: this.identifier.sp3.sp3Id,
        nominalPurchase: this.dataForm.step2.add.nominalPurchase,
        nominalInstallment: this.dataForm.step2.add.nominalInstallment,
        isProfitShareBankPercentage: this.dataForm.step2.add
          .isProfitShareBankPercentage,
        journalRecord: this.dataForm.step2.add.journalRecord,
        akadNumberNotaril: this.dataForm.step2.add.akadNumberNotaril,
        akadNumber: this.dataForm.step2.add.akadNumber,
        referalOfficeId: this.dataForm.step2.add.referalOfficeId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step2.addSubLoanAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "transaction-loan",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.resetFormSubLoanAccount("ADD");
                    this.getSubLoanAccount();
                    this.MIXINS_METHOD_getRefferenceLoanAccount();
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step2.addSubLoanAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    resetFormSubLoanAccount(type) {
      switch (type) {
        case "ADD":
          this.dataForm.step2.add.loanAkadTypeAcronym = "";
          this.dataForm.step2.add.loanAccountNumber = "";
          this.dataForm.step2.add.akadNumber = "";
          this.dataForm.step2.add.timePeriod = 0;
          this.dataForm.step2.add.nominalLoan = 0;
          this.dataForm.step2.add.akadDate = "";
          this.dataForm.step2.add.startDate = "";
          this.dataForm.step2.add.endDate = "";
          this.dataForm.step2.add.rateMargin = 0;
          this.dataForm.step2.add.nominalMargin = 0;
          this.dataForm.step2.add.nominalTotal = 0;
          this.dataForm.step2.add.rateFlat = 0;
          this.dataForm.step2.add.rateEffective = 0;
          this.dataForm.step2.add.nominalInstallment = 0;
          this.dataForm.step2.add.purposeLoan = "";
          this.dataForm.step2.add.isSmsNotification = false;
          this.dataForm.step2.add.isNeedCollateral = false;
          this.dataForm.step2.add.nominalCollateral = 0;
          this.dataForm.step2.add.collateralSharedPercent = 0;
          this.dataForm.step2.add.ujrahDropGroup = 0;
          this.dataForm.step2.add.ujrahDropGroupSub = 0;
          this.dataForm.step2.add.accountSection = 0;
          this.dataForm.step2.add.feeAdmin = 0;
          this.dataForm.step2.add.feeMaterai = 0;
          this.dataForm.step2.add.feeNotaris = 0;
          this.dataForm.step2.add.feeInsuranceLife = 0;
          this.dataForm.step2.add.feeInsuranceLoss = 0;
          this.dataForm.step2.add.feeInsuranceVehicle = 0;
          this.dataForm.step2.add.blockedFund = 0;
          this.dataForm.step2.add.feeTotal = 0;
          this.dataForm.step2.add.collectorId = "";
          this.dataForm.step2.add.participateBank = 0;
          this.dataForm.step2.add.participateCif = 0;
          this.dataForm.step2.add.profitShareBank = 0;
          this.dataForm.step2.add.profitProjection = 0;
          this.dataForm.step2.add.isRelated = false;
          this.dataForm.step2.add.margin = false;
          this.dataForm.step2.add.loanId = "";
          this.dataForm.step2.add.savingAccountNumberDro = "";
          this.dataForm.step2.add.savingAccountNumberInstallment = "";
          this.dataForm.step2.add.loanCategoryId = "";
          this.dataForm.step2.add.loanCollateralId = "";
          this.dataForm.step2.add.loanGroupId = "";
          this.dataForm.step2.add.accountOfficerHandleId = "";
          this.dataForm.step2.add.accountOfficerPromoId = "";
          this.dataForm.step2.add.isLineFacility = false;
          this.dataForm.step2.add.loanAccountId = "";
          this.dataForm.step2.add.loanAccountParentId = "";
          this.dataForm.step2.add.referalOfficeId = "";
          this.dataForm.step2.add.nominalPurchase = 0;
          this.dataForm.step2.add.journalRecord.journalRecordDetails = [];
          this.changeSelectTypeAkadAddSubLoanAccount();
          break;

        case "EDIT":
          this.dataForm.step2.edit.loanAkadTypeAcronym = "";
          this.dataForm.step2.edit.loanAccountNumber = "";
          this.dataForm.step2.edit.akadNumber = "";
          this.dataForm.step2.edit.timePeriod = 0;
          this.dataForm.step2.edit.nominalLoan = 0;
          this.dataForm.step2.edit.akadDate = "";
          this.dataForm.step2.edit.startDate = "";
          this.dataForm.step2.edit.endDate = "";
          this.dataForm.step2.edit.rateFlat = 0;
          this.dataForm.step2.edit.rateEffective = 0;
          this.dataForm.step2.edit.purposeLoan = "";
          this.dataForm.step2.edit.isSmsNotification = false;
          this.dataForm.step2.edit.isNeedCollateral = false;
          this.dataForm.step2.edit.nominalCollateral = 0;
          this.dataForm.step2.edit.collateralSharedPercent = 0;
          this.dataForm.step2.edit.ujrahDropGroup = 0;
          this.dataForm.step2.edit.ujrahDropGroupSub = 0;
          this.dataForm.step2.edit.accountSection = 0;
          this.dataForm.step2.edit.feeAdmin = 0;
          this.dataForm.step2.edit.feeMaterai = 0;
          this.dataForm.step2.edit.feeNotaris = 0;
          this.dataForm.step2.edit.feeInsuranceLife = 0;
          this.dataForm.step2.edit.feeInsuranceLoss = 0;
          this.dataForm.step2.edit.feeInsuranceVehicle = 0;
          this.dataForm.step2.edit.blockedFund = 0;
          this.dataForm.step2.edit.feeTotal = 0;
          this.dataForm.step2.edit.collectorId = "";
          this.dataForm.step2.edit.participateBank = 0;
          this.dataForm.step2.edit.participateCif = 0;
          this.dataForm.step2.edit.profitShareBank = 0;
          this.dataForm.step2.edit.profitProjection = 0;
          this.dataForm.step2.edit.isRelated = false;
          this.dataForm.step2.edit.margin = false;
          this.dataForm.step2.edit.loanId = "";
          this.dataForm.step2.edit.savingAccountNumberDro = "";
          this.dataForm.step2.edit.savingAccountNumberInstallment = "";
          this.dataForm.step2.edit.loanCategoryId = "";
          this.dataForm.step2.edit.loanCollateralId = "";
          this.dataForm.step2.edit.loanGroupId = "";
          this.dataForm.step2.edit.accountOfficerHandleId = "";
          this.dataForm.step2.edit.accountOfficerPromoId = "";
          this.dataForm.step2.edit.isLineFacility = false;
          this.dataForm.step2.edit.loanAccountId = "";
          this.dataForm.step2.edit.loanAccountParentId = "";
          this.dataForm.step2.edit.sp3Id = "";
          this.dataForm.step2.edit.cifMobilePhoneNumber = "";
          this.dataForm.step2.edit.referalOfficeId = "";
          this.dataForm.step2.edit.journalRecord.journalRecordDetails = [];
          this.dataForm.step2.edit.notaryPublicAccountNumber = "";
          this.dataForm.step2.edit.insuranceAccountNumber = "";
          this.changeSelectTypeAkadInEditSubLoanAccount();
          break;

        default:
          return;
          break;
      }
    },
    changeIsNeedCollateralSubLoanAccount(event) {
      if (event === false) {
        this.dataForm.step2.add.nominalCollateral = "";
      }
    },
    async changePaginationSubLoanAccount(event) {
      this.table.data.step2 = [];
      this.property.listElement.step2.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.identifier.loanAccountNumber,
            cifIdName: "",
            isSubContract: true,
            page: event - 1,
            size: this.property.listElement.step2.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step2 = resp.data.data.pageResponse.content;
            this.property.listElement.step2.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetDataSubLoanAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSubLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step2.downloading = false;
        }, timeout);
      }
    },
    failedGetDataSubLoanAccount(resp) {
      this.table.data.step2 = [];
      this.property.listElement.step2.rows = 0;
      this.property.listElement.step2.message = resp.data.message;
    },
    async authorizationSubLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          title: "Konfirmasi",
          message: `Otorisasi Nomor Kontrak & Setuju Droping sebesar ${
            props.row.loanAkadTypeAcronym ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
            props.row.loanAkadTypeAcronym ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
              ? props.row.participateBank
                ? props.row.participateBank
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
              : props.row.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
                props.row.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
                props.row.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
              ? props.row.nominalPurchase
                ? props.row.nominalPurchase
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
              : props.row.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH ||
                props.row.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_LINE_FACILITY
              ? props.row.nominalLoan
                ? props.row.nominalLoan
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
              : 0
          } ?`,
          cancelText: "Batal",
          confirmText: "Konfirmasi",
          closeOnConfirm: false,
          type: "is-warning",
          hasIcon: true,
          onConfirm: async (value, { close }) => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/authorized/" + props.row.loanAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                close();
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSubLoanAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteSubLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "transaction-loan/" + props.row.loanAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSubLoanAccount();
                  this.MIXINS_METHOD_getRefferenceLoanAccount();
                }, 500);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    changeStartDateSubLoanAccount() {
      if (this.dataForm.step2.add.timePeriod) {
        this.changeTimePeriodAddSubLoanAccount();
      }
    },
    changeTimePeriodAddSubLoanAccount() {
      if (this.dataForm.step2.add.startDate !== "") {
        var periode =
          this.dataForm.step2.add.timePeriod === 0 ||
          this.dataForm.step2.add.timePeriod === ""
            ? 1
            : this.dataForm.step2.add.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step2.add.endDate = calculate;
      }
      if (
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateSubLoanAccountAdd();
      }
      if (
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step2.add.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
        this.calculateProyeksiAngsuranAddSubLoanAccount();
      }
    },
    changeEndDateAddSubLoanAccount() {
      if (
        this.dataForm.step2.add.endDate !== "" &&
        this.dataForm.step2.add.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step2.add.endDate);
        var b = Moment(this.dataForm.step2.add.startDate);
        this.dataForm.step2.add.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step2.add.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step2.add.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step2.add.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateSubLoanAccountAdd();
        }
        if (
          this.dataForm.step2.add.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step2.add.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
          this.calculateProyeksiAngsuranAddSubLoanAccount();
        }
      }
    },
    calculateFeeSubLoanAccount() {
      this.dataForm.step2.add.feeTotal =
        (!this.dataForm.step2.add.feeAdmin
          ? 0
          : this.dataForm.step2.add.feeAdmin) +
        (!this.dataForm.step2.add.feeMaterai
          ? 0
          : this.dataForm.step2.add.feeMaterai) +
        (!this.dataForm.step2.add.feeNotaris
          ? 0
          : this.dataForm.step2.add.feeNotaris) +
        (!this.dataForm.step2.add.feeInsuranceLife
          ? 0
          : this.dataForm.step2.add.feeInsuranceLife) +
        (!this.dataForm.step2.add.feeInsuranceLoss
          ? 0
          : this.dataForm.step2.add.feeInsuranceLoss) +
        (!this.dataForm.step2.add.feeInsuranceVehicle
          ? 0
          : this.dataForm.step2.add.feeInsuranceVehicle);
    },
    calculateParticipateAddSubLoanAccount(type) {
      this.dataForm.step2.add.totalParticipate =
        this.dataForm.step2.add.participateBank +
        this.dataForm.step2.add.participateCif;
      this.dataForm.step2.add.nominalLoan = this.dataForm.step2.add.participateBank;
      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
        this.calculateProyeksiAngsuranAddSubLoanAccount();
      }
    },
    changeProfitShareBankPercentAddSubLoanAccount() {
      switch (this.dataForm.step2.add.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step2.add.profitShareBankProjection =
            (this.dataForm.step2.add.profitProjection *
              this.dataForm.step2.add.profitShareBank) /
            100;

          break;
        case false:
          this.dataForm.step2.add.profitShareBankProjection = this.dataForm.step2.add.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
      this.dataForm.step2.add.nominalLoan = this.dataForm.step2.add.participateBank;
      this.calculateProyeksiAngsuranAddSubLoanAccount();
    },
    changeTipeBagiHasilBankAddSubLoanAccount() {
      switch (this.dataForm.step2.add.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step2.add.profitShareBank !== 0 &&
            this.dataForm.step2.add.profitProjection !== 0
          ) {
            this.dataForm.step2.add.profitShareBank =
              (this.dataForm.step2.add.profitShareBank /
                this.dataForm.step2.add.profitProjection) *
              100;
            this.dataForm.step2.add.profitShareBankProjection =
              (this.dataForm.step2.add.profitProjection *
                this.dataForm.step2.add.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step2.add.profitShareBank = 0;
          this.dataForm.step2.add.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step2.add.profitShareBank !== 0 &&
            this.dataForm.step2.add.profitProjection !== 0
          ) {
            this.dataForm.step2.add.profitShareBankProjection =
              (this.dataForm.step2.add.profitProjection *
                this.dataForm.step2.add.profitShareBank) /
              100;
            this.dataForm.step2.add.profitShareBank =
              (this.dataForm.step2.add.profitProjection *
                this.dataForm.step2.add.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step2.add.profitShareBank = 0;
          this.dataForm.step2.add.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitProjectionAddSubLoanAccount(type) {
      if (this.dataForm.step2.add.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentAddSubLoanAccount();
        this.dataForm.step2.add.nominalLoan = this.dataForm.step2.add.participateBank;
        this.calculateProyeksiAngsuranAddSubLoanAccount();
      }
      this.calculateRateEffectiveAnnuallyAddSubLoanAccount();
    },
    changeNominalMarginAddSubLoanAccount() {
      if (
        this.dataForm.step2.add.nominalMargin &&
        this.dataForm.step2.add.nominalPurchase &&
        this.dataForm.step2.add.timePeriod
      ) {
        this.dataForm.step2.add.totalMargin =
          (this.dataForm.step2.add.nominalMargin /
            this.dataForm.step2.add.nominalPurchase) *
          100;
        this.dataForm.step2.add.rateMargin =
          (this.dataForm.step2.add.totalMargin /
            this.dataForm.step2.add.timePeriod) *
          12;
      }
      this.calculateRateSubLoanAccountAdd();
    },
    // calculateRateSubLoanAccountAdd() {
    //   this.dataForm.step2.add.rateFlat = this.dataForm.step2.add.rateMargin;
    //   if (
    //     this.dataForm.step2.add.margin === false &&
    //     this.dataForm.step2.add.nominalPurchase &&
    //     this.dataForm.step2.add.rateMargin
    //   ) {
    //     const payload = {
    //       rateFlat: this.dataForm.step2.add.rateMargin,
    //       principal: this.dataForm.step2.add.nominalPurchase,
    //     };
    //     this.convertToEffectiveAddSubLoanAccount(
    //       payload.rateFlat,
    //       payload.principal
    //     );
    //   }
    //   this.dataForm.step2.add.totalMargin = this.dataForm.step2.add.timePeriod
    //     ? (this.dataForm.step2.add.rateMargin / 12) *
    //       this.dataForm.step2.add.timePeriod
    //     : 0;
    //   this.dataForm.step2.add.nominalMargin = this.dataForm.step2.add
    //     .totalMargin
    //     ? (this.dataForm.step2.add.totalMargin *
    //         this.dataForm.step2.add.nominalPurchase) /
    //       100
    //     : 0;
    //   this.dataForm.step2.add.totalSellingPrice = this.dataForm.step2.add
    //     .nominalMargin
    //     ? this.dataForm.step2.add.nominalMargin +
    //       this.dataForm.step2.add.nominalPurchase
    //     : 0;
    //   this.dataForm.step2.add.nominalLoan = this.dataForm.step2.add.totalSellingPrice;
    //   this.dataForm.step2.add.nominalInstallment =
    //     this.dataForm.step2.add.nominalLoan /
    //     this.dataForm.step2.add.timePeriod;
    // },
    closeModalEditSubLoanAccount() {
      this.property.modal.step2.showModalEditSubLoanAccount = false;
      this.resetFormSubLoanAccount("EDIT");
    },
    changeSelectTypeAkadInEditSubLoanAccount() {
      this.resetFormNominalPembiayaanInEditSubLoanAccount();
      const filterAkad = this.options.step2.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym ===
          this.dataForm.step2.edit.loanAkadTypeAcronym
      );
      this.dataForm.step2.edit.loanId = "";
      this.options.step2.produkPembiayaanByAkad.edit = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    resetFormNominalPembiayaanInEditSubLoanAccount() {
      this.dataForm.step2.edit.participateBank = 0;
      this.dataForm.step2.edit.participateCif = 0;
      this.dataForm.step2.edit.rateFlat = 0;
      this.dataForm.step2.edit.rateEffective = 0;
      this.dataForm.step2.edit.profitShareBank = 0;
      this.dataForm.step2.edit.profitProjection = 0;
      this.dataForm.step2.edit.nominalPurchase = 0;
      this.dataForm.step2.edit.nominalLoan = 0;
      this.dataForm.step2.edit.rateMargin = 0;
      this.dataForm.step2.edit.totalMargin = 0;
      this.dataForm.step2.edit.nominalMargin = 0;
      this.dataForm.step2.edit.totalParticipate = 0;
      this.dataForm.step2.edit.nominalInstallment = 0;
      this.dataForm.step2.edit.totalSellingPrice = 0;
      this.dataForm.step2.edit.profitShareBankProjection = 0;
      this.dataForm.step2.edit.isProfitShareBankPercentage = false;
    },
    changeProdukPembiayaanEditSubLoanAccount() {
      this.getLoanChartOfAccountByLoanIdEditSubLoanAccount();
      this.options.step2.produkPembiayaan.map((i) => {
        if (i.value === this.dataForm.step2.edit.loanId) {
          this.dataForm.step2.edit.journalRecord.productCode = i.loanCode;
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step2.edit.margin = true;
              this.dataForm.step2.edit.rateEffective = 0;

              break;
            case "2":
              this.dataForm.step2.edit.margin = false;
              this.dataForm.step2.edit.rateFlat = 0;

              break;

            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step2.edit.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step2.edit.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step2.edit.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateSubLoanAccountEdit("manual_input");
      }
      if (
        this.dataForm.step2.edit.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step2.edit.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
        this.calculateProyeksiAngsuranEditSubLoanAccount();
      }
    },
    async getLoanChartOfAccountByLoanIdEditSubLoanAccount() {
      try {
        this.dataForm.step2.edit.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step2.edit.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const pairType = i.pairType;
            const amount = 0;
            this.dataForm.step2.edit.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              pairChartOfAccountId,
              label,
              labelInputComponent,
              positionMutation,
              pairPositionMutation,
              chartOfAccountCode,
              pairChartOfAccountCode,
              pairType,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    calculateRateSubLoanAccountEdit(triggerby) {
      this.dataForm.step2.edit.rateFlat = this.dataForm.step2.edit.rateMargin;
      if (triggerby === "manual_input") {
        if (
          this.dataForm.step2.edit.margin === false &&
          this.dataForm.step2.edit.nominalPurchase &&
          this.dataForm.step2.edit.rateMargin
        ) {
          const payload = {
            rateFlat: this.dataForm.step2.edit.rateMargin,
            principal: this.dataForm.step2.edit.nominalPurchase,
          };
          this.convertToEffectiveEditSubLoanAccount(
            payload.rateFlat,
            payload.principal
          );
        }
      }
      this.dataForm.step2.edit.totalMargin = this.dataForm.step2.edit.timePeriod
        ? (this.dataForm.step2.edit.rateMargin / 12) *
          this.dataForm.step2.edit.timePeriod
        : 0;
      this.dataForm.step2.edit.nominalMargin = this.dataForm.step2.edit
        .totalMargin
        ? (this.dataForm.step2.edit.totalMargin *
            this.dataForm.step2.edit.nominalPurchase) /
          100
        : 0;
      this.dataForm.step2.edit.totalSellingPrice = this.dataForm.step2.edit
        .nominalMargin
        ? this.dataForm.step2.edit.nominalMargin +
          this.dataForm.step2.edit.nominalPurchase
        : 0;
      this.dataForm.step2.edit.nominalLoan = this.dataForm.step2.edit.totalSellingPrice;
      this.dataForm.step2.edit.nominalInstallment =
        this.dataForm.step2.edit.nominalLoan /
        this.dataForm.step2.edit.timePeriod;
    },
    calculateRateEffectiveAnnuallyEditSubLoanAccount() {
      if (
        this.dataForm.step2.edit.timePeriod === 0 ||
        this.dataForm.step2.edit.participateBank === 0 ||
        this.dataForm.step2.edit.profitShareBankProjection === 0
      ) {
        this.dataForm.step2.edit.rateEffective = 0;
        this.dataForm.step2.edit.rateFlat = 0;
        return;
      }
      const calculateRate =
        (this.dataForm.step2.edit.profitShareBankProjection /
          this.dataForm.step2.edit.participateBank) *
        100;
      if (this.dataForm.step2.edit.margin === false) {
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step2.edit.timePeriod) * 12,
          principal: this.dataForm.step2.edit.participateBank,
        };
        this.convertToEffectiveEditSubLoanAccount(
          payload.rateFlat,
          payload.principal
        );
      }
      this.dataForm.step2.edit.rateFlat =
        (calculateRate / this.dataForm.step2.edit.timePeriod) * 12;
    },
    calculateProyeksiAngsuranEditSubLoanAccount() {
      if (
        this.dataForm.step2.edit.nominalLoan !== 0 &&
        this.dataForm.step2.edit.timePeriod !== 0
      ) {
        this.dataForm.step2.edit.nominalInstallment =
          this.dataForm.step2.edit.nominalLoan /
          this.dataForm.step2.edit.timePeriod;
        return;
      }
      this.dataForm.step2.edit.nominalInstallment = 0;
    },
    async convertToEffectiveEditSubLoanAccount(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step2.edit.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step2.edit.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    confirmationModalAkadNumberEditSubLoanAccount() {
      this.$buefy.dialog.alert({
        title: "Data SP3",
        message: `
        <span><b>Tanggal SP3</b> : ${this.identifier.sp3.sp3Date}</span>
          <br />
          <span><b>Perihal</b> :  ${this.identifier.sp3.sp3Subject}</span>
          <br />
          <span><b>No. Sp3</b> : ${this.identifier.sp3.sp3DocNumber} </span>
        `,
        type: "is-info",
        confirmText: "Tutup",
        canCancel: ["outside"],
      });
    },
    changeIsNeedCollateralEditSubLoanAccount(event) {
      if (event === false) {
        this.dataForm.step2.edit.nominalCollateral = 0;
      }
    },
    changeTipeBagiHasilBankEditSubLoanAccount() {
      switch (this.dataForm.step2.edit.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step2.edit.profitShareBank !== 0 &&
            this.dataForm.step2.edit.profitProjection !== 0
          ) {
            this.dataForm.step2.edit.profitShareBank =
              (this.dataForm.step2.edit.profitShareBank /
                this.dataForm.step2.edit.profitProjection) *
              100;
            this.dataForm.step2.edit.profitShareBankProjection =
              (this.dataForm.step2.edit.profitProjection *
                this.dataForm.step2.edit.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step2.edit.profitShareBank = 0;
          this.dataForm.step2.edit.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step2.edit.profitShareBank !== 0 &&
            this.dataForm.step2.edit.profitProjection !== 0
          ) {
            this.dataForm.step2.edit.profitShareBankProjection =
              (this.dataForm.step2.edit.profitProjection *
                this.dataForm.step2.edit.profitShareBank) /
              100;
            this.dataForm.step2.edit.profitShareBank =
              (this.dataForm.step2.edit.profitProjection *
                this.dataForm.step2.edit.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step2.edit.profitShareBank = 0;
          this.dataForm.step2.edit.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitShareBankPercentEditSubLoanAccount() {
      switch (this.dataForm.step2.edit.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step2.edit.profitShareBankProjection =
            (this.dataForm.step2.edit.profitProjection *
              this.dataForm.step2.edit.profitShareBank) /
            100;

          break;
        case false:
          this.dataForm.step2.edit.profitShareBankProjection = this.dataForm.step2.edit.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
      this.dataForm.step2.edit.nominalLoan = this.dataForm.step2.edit.participateBank;
      this.calculateProyeksiAngsuranEditSubLoanAccount();
    },
    async getSubLoanAccountById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.resetFormSubLoanAccount("EDIT");
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "transaction-loan/findById/" + props.row.loanAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step2.edit.insuranceAccountNumber =
              resp.data.data.insuranceAccountNumber;
            this.dataForm.step2.edit.notaryPublicAccountNumber =
              resp.data.data.notaryPublicAccountNumber;
            this.dataForm.step2.edit.akadNumberNotaril =
              resp.data.data.akadNumberNotaril;
            this.dataForm.step2.edit.loanAkadTypeAcronym = resp.data.data.mloan
              ? resp.data.data.mloan.rloanAkadType
                ? resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym
                : ""
              : "";
            this.dataForm.step2.edit.referalOfficeId = resp.data.data
              .referalOffice
              ? resp.data.data.referalOffice.officeId
              : "";
            this.dataForm.step2.edit.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step2.edit.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step2.edit.createdDate = resp.data.data.createdDate;
            this.dataForm.step2.edit.updatedDate = resp.data.data.updatedDate;
            this.dataForm.step2.edit.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step2.edit.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step2.edit.authorizedDate =
              resp.data.data.authorizedDate;
            this.dataForm.step2.edit.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step2.edit.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.changeSelectTypeAkadInEditSubLoanAccount();
            this.dataForm.step2.edit.akadNumber = resp.data.data.akadNumber;
            this.dataForm.step2.edit.akadDate = resp.data.data.akadDate;
            this.dataForm.step2.edit.startDate = resp.data.data.startDate;
            this.dataForm.step2.edit.endDate = resp.data.data.endDate;
            this.dataForm.step2.edit.timePeriod = resp.data.data.timePeriod;
            this.dataForm.step2.edit.billDay = resp.data.data.billDay;
            this.dataForm.step2.edit.isAutodebt = resp.data.data.isAutodebt;
            this.dataForm.step2.edit.nominalLoan = resp.data.data.nominalLoan;
            this.dataForm.step2.edit.rateFlat = resp.data.data.rateFlat;
            this.dataForm.step2.edit.gracePeriode = resp.data.data.gracePeriode;
            this.dataForm.step2.edit.graceReason = resp.data.data.graceReason;
            this.dataForm.step2.edit.rateEffective =
              resp.data.data.rateEffective;
            this.dataForm.step2.edit.nominalInstallment =
              resp.data.data.nominalInstallment;
            this.dataForm.step2.edit.purposeLoan = resp.data.data.purposeLoan;
            this.dataForm.step2.edit.isSmsNotification =
              resp.data.data.isSmsNotification;
            this.dataForm.step2.edit.isNeedCollateral =
              resp.data.data.isNeedCollateral;
            this.dataForm.step2.edit.nominalCollateral =
              resp.data.data.nominalCollateral;
            this.dataForm.step2.edit.collateralSharedPercent =
              resp.data.data.collateralSharedPercent;
            this.dataForm.step2.edit.ujrahDropGroup =
              resp.data.data.ujrahDropGroup;
            this.dataForm.step2.edit.ujrahDropGroupSub =
              resp.data.data.ujrahDropGroupSub;
            this.dataForm.step2.edit.accountSection =
              resp.data.data.accountSection;
            this.dataForm.step2.edit.feeAdmin = resp.data.data.feeAdmin;
            this.dataForm.step2.edit.feeMaterai = resp.data.data.feeMaterai;
            this.dataForm.step2.edit.feeNotaris = resp.data.data.feeNotaris;
            this.dataForm.step2.edit.feeInsuranceLife =
              resp.data.data.feeInsuranceLife;
            this.dataForm.step2.edit.feeInsuranceLoss =
              resp.data.data.feeInsuranceLoss;
            this.dataForm.step2.edit.feeInsuranceVehicle =
              resp.data.data.feeInsuranceVehicle;
            this.dataForm.step2.edit.blockedFund = resp.data.data.blockedFund;
            this.dataForm.step2.edit.feeTotal = resp.data.data.feeTotal;
            this.dataForm.step2.edit.accountOfficerCollectorId =
              resp.data.data.accountOfficerCollectorId;
            this.dataForm.step2.edit.participateBank =
              resp.data.data.participateBank;
            this.dataForm.step2.edit.participateCif =
              resp.data.data.participateCif;
            this.dataForm.step2.edit.profitShareBank =
              resp.data.data.profitShareBank;
            this.dataForm.step2.edit.profitProjection =
              resp.data.data.profitProjection;
            this.dataForm.step2.edit.profitShareBankProjection =
              resp.data.data.profitShareBank;
            this.dataForm.step2.edit.isRelated = resp.data.data.isRelated;
            this.dataForm.step2.edit.margin =
              resp.data.data.mloan.rloanMarginCalculate
                .loanMarginCalculateId === "1"
                ? true
                : false;
            this.dataForm.step2.edit.loanId = resp.data.data.mloan
              ? resp.data.data.mloan.loanId
              : "";
            this.dataForm.step2.edit.savingAccountNumberDro = resp.data.data
              .tsavingAccountDroping
              ? resp.data.data.tsavingAccountDroping.accountNumber
              : "";
            this.dataForm.step2.edit.savingAccountNumberInstallment = resp.data
              .data.tsavingAccountInstallment
              ? resp.data.data.tsavingAccountInstallment.accountNumber
              : "";
            this.dataForm.step2.edit.loanCategoryId = resp.data.data
              .rloanGroupCategory
              ? resp.data.data.rloanGroupCategory.loanGroupCategoryId
              : "";
            this.dataForm.step2.edit.loanCollateralId = resp.data.data
              .mloanCollateral
              ? resp.data.data.mloanCollateral.loanCollateralId
              : "";
            this.dataForm.step2.edit.loanGroupParentId = resp.data.data
              .mloanGroup
              ? resp.data.data.mloanGroup.loanGroupParentId
                ? resp.data.data.mloanGroup.loanGroupParentId.loanGroupId
                : ""
              : "";
            this.dataForm.step2.edit.loanGroupId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupId
              : "";
            this.changeLoanGroupLoanAccountEditSubLoanAccount(
              this.dataForm.step2.edit.loanGroupId
            );
            this.dataForm.step2.edit.accountOfficerHandleId =
              resp.data.data.accountOfficerHandleId;
            this.dataForm.step2.edit.accountOfficerPromoId =
              resp.data.data.accountOfficerPromoId;
            this.dataForm.step2.edit.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.dataForm.step2.edit.loanAccountId =
              resp.data.data.loanAccountId;
            this.dataForm.step2.edit.sp3Id = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.loanSp3Id
              : "";
            this.dataForm.step2.edit.loanAccountParentId = resp.data.data
              .loanAccountParent
              ? resp.data.data.loanAccountParent.loanAccountId
              : "";
            this.dataForm.step2.edit.cifMobilePhoneNumber = resp.data.data
              .tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step2.edit.nominalPurchase =
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
                ? resp.data.data.nominalPurchase
                : 0;
            if (
              this.dataForm.step2.edit.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              this.dataForm.step2.edit.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              this.dataForm.step2.edit.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ) {
              this.dataForm.step2.edit.rateMargin = resp.data.data.rateFlat;
              this.calculateRateSubLoanAccountEdit("get_by_id");
            }
            if (
              this.dataForm.step2.edit.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
              this.dataForm.step2.edit.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ) {
              this.dataForm.step2.edit.totalParticipate =
                this.dataForm.step2.edit.participateBank +
                this.dataForm.step2.edit.participateCif;
            }
            this.getRefferenceLoanChartOfAccountByLoanIdEditSubLoanAccount(
              resp.data.data.journalRecord
            );
            this.property.modal.step2.showModalEditSubLoanAccount = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async getRefferenceLoanChartOfAccountByLoanIdEditSubLoanAccount(
      currentJournalRecord
    ) {
      try {
        this.dataForm.step2.edit.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step2.edit.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          Promise.all(
            resp.data.data.map((i, index) => {
              console.log(index, i);
              const productLedgerId = i.loanChartOfAccountId;
              const chartOfAccountId = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountId
                : "";
              const label = i.productLedger
                ? i.productLedger.productLedgerName
                : "";
              const positionMutation = i.mutationPosition;
              const pairPositionMutation = i.pairMutationPosition;
              const labelInputComponent = i.chartOfAccount
                ? i.chartOfAccount.description
                : "";
              const pairChartOfAccountId = i.pairChartOfAccount
                ? i.pairChartOfAccount.chartOfAccountId
                : "";
              const chartOfAccountCode = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountCode
                : "";
              const pairChartOfAccountCode = i.pairChartOfAccount
                ? i.pairChartOfAccount.pairChartOfAccountCode
                : "";
              const pairType = i.pairType;
              const amount = 0;
              this.dataForm.step2.edit.journalRecord.journalRecordDetails.push({
                productLedgerId,
                chartOfAccountId,
                pairChartOfAccountId,
                label,
                labelInputComponent,
                positionMutation,
                pairPositionMutation,
                chartOfAccountCode,
                pairChartOfAccountCode,
                pairType,
                amount,
                index: index,
              });
            })
          ).then(() => {
            if (currentJournalRecord) {
              this.insertJournalRecordEditSubLoanAccount(currentJournalRecord);
            }
          });
        }
      } catch (error) {}
    },
    insertJournalRecordEditSubLoanAccount(currentJournalRecord) {
      this.dataForm.step2.edit.journalRecord.journalRecordDetails.map(
        (index) => {
          currentJournalRecord.journalRecordDetails.map((i) => {
            if (i.chartOfAccountId === index.chartOfAccountId) {
              index.amount = i.amount;
            }
          });
        }
      );
    },
    changeNominalMarginEditSubLoanAccount() {
      if (
        this.dataForm.step2.edit.nominalMargin &&
        this.dataForm.step2.edit.nominalPurchase &&
        this.dataForm.step2.edit.timePeriod
      ) {
        this.dataForm.step2.edit.totalMargin =
          (this.dataForm.step2.edit.nominalMargin /
            this.dataForm.step2.edit.nominalPurchase) *
          100;
        this.dataForm.step2.edit.rateMargin =
          (this.dataForm.step2.edit.totalMargin /
            this.dataForm.step2.edit.timePeriod) *
          12;
      }
      this.calculateRateSubLoanAccountEdit("manual_input");
    },
    async editSubLoanAccount() {
      const payload = {
        loanAccountId: this.dataForm.step2.edit.loanAccountId,
        akadDate: this.dataForm.step2.edit.akadDate,
        startDate: this.dataForm.step2.edit.startDate,
        endDate: this.dataForm.step2.edit.endDate,
        timePeriod: this.dataForm.step2.edit.timePeriod,
        billDay: this.dataForm.step2.edit.billDay,
        isAutodebt: this.dataForm.step2.edit.isAutodebt,
        nominalLoan: this.dataForm.step2.edit.nominalLoan,
        rateMargin: this.dataForm.step2.edit.rateMargin,
        nominalMargin: this.dataForm.step2.edit.nominalMargin,
        gracePeriode: this.dataForm.step2.edit.gracePeriode,
        graceReason: this.dataForm.step2.edit.graceReason,
        nominalTotal: this.dataForm.step2.edit.nominalTotal,
        rateFlat: this.dataForm.step2.edit.rateFlat,
        rateEffective: this.dataForm.step2.edit.rateEffective,
        nominalInstallment: this.dataForm.step2.edit.nominalInstallment,
        purposeLoan: this.dataForm.step2.edit.purposeLoan,
        isSmsNotification: this.dataForm.step2.edit.isSmsNotification,
        isNeedCollateral: this.dataForm.step2.edit.isNeedCollateral,
        nominalCollateral: this.dataForm.step2.edit.nominalCollateral,
        collateralSharedPercent: this.dataForm.step2.edit
          .collateralSharedPercent,
        ujrahDropGroup: this.dataForm.step2.edit.ujrahDropGroup,
        ujrahDropGroupSub: this.dataForm.step2.edit.ujrahDropGroupSub,
        accountSection: this.dataForm.step2.edit.accountSection,
        feeAdmin: this.dataForm.step2.edit.feeAdmin,
        feeMaterai: this.dataForm.step2.edit.feeMaterai,
        feeNotaris: this.dataForm.step2.edit.feeNotaris,
        feeInsuranceLife: this.dataForm.step2.edit.feeInsuranceLife,
        feeInsuranceLoss: this.dataForm.step2.edit.feeInsuranceLoss,
        feeInsuranceVehicle: this.dataForm.step2.edit.feeInsuranceVehicle,
        blockedFund: this.dataForm.step2.edit.blockedFund,
        feeTotal: this.dataForm.step2.edit.feeTotal,
        accountOfficerCollectorId: this.dataForm.step2.edit
          .accountOfficerCollectorId,
        participateBank: this.dataForm.step2.edit.participateBank,
        participateCif: this.dataForm.step2.edit.participateCif,
        profitShareBank: this.dataForm.step2.edit.profitShareBank,
        profitProjection: this.dataForm.step2.edit.profitProjection,
        isRelated: this.dataForm.step2.edit.isRelated,
        sp3Id: this.dataForm.step2.edit.sp3Id,
        loanId: this.dataForm.step2.edit.loanId,
        savingAccountNumberDro: this.dataForm.step2.edit.savingAccountNumberDro,
        savingAccountNumberInstallment: this.dataForm.step2.edit
          .savingAccountNumberInstallment,
        loanCategoryId: this.dataForm.step2.edit.loanCategoryId,
        loanCollateralId: this.dataForm.step2.edit.loanCollateralId,
        loanGroupId: this.dataForm.step2.edit.loanGroupId,
        accountOfficerHandleId: this.dataForm.step2.edit.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.step2.edit.accountOfficerPromoId,
        loanAccountParentId: this.dataForm.step2.edit.loanAccountParentId,
        isLineFacility: this.dataForm.step2.edit.isLineFacility,
        nominalPurchase: this.dataForm.step2.edit.nominalPurchase,
        isProfitShareBankPercentage: this.dataForm.step2.edit
          .isProfitShareBankPercentage,
        journalRecord: this.dataForm.step2.edit.journalRecord,
        akadNumberNotaril: this.dataForm.step2.edit.akadNumberNotaril,
        akadNumber: this.dataForm.step2.edit.akadNumber,
        referalOfficeId: this.dataForm.step2.edit.referalOfficeId,
        notaryPublicAccountNumber: this.dataForm.step2.edit
          .notaryPublicAccountNumber,
        insuranceAccountNumber: this.dataForm.step2.edit.insuranceAccountNumber,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step2.editSubLoanAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/" + this.dataForm.step2.edit.loanAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.step2.showModalEditSubLoanAccount = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormSubLoanAccount("EDIT");
                      this.getSubLoanAccount();
                    }, 500);
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step2.editSubLoanAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    changeStartDateInEditSubLoanAccount() {
      if (this.dataForm.step2.edit.timePeriod) {
        this.changeTimePeriodEditSubLoanAccount();
      }
    },
    changeEndDateInEditSubLoanAccount() {
      if (
        this.dataForm.step2.edit.endDate !== "" &&
        this.dataForm.step2.edit.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step2.edit.endDate);
        var b = Moment(this.dataForm.step2.edit.startDate);
        this.dataForm.step2.edit.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateSubLoanAccountEdit("manual_input");
        }
        if (
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
          this.calculateProyeksiAngsuranEditSubLoanAccount();
        }
      }
    },
    changeTimePeriodEditSubLoanAccount() {
      if (this.dataForm.step2.edit.startDate !== "") {
        var periode =
          this.dataForm.step2.edit.timePeriod === 0 ||
          this.dataForm.step2.edit.timePeriod === ""
            ? 1
            : this.dataForm.step2.edit.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step2.edit.endDate = calculate;
        if (
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateSubLoanAccountEdit("manual_input");
        }
        if (
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step2.edit.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
          this.calculateProyeksiAngsuranEditSubLoanAccount();
        }
      }
    },
    calculateFeeEditSubLoanAccount() {
      this.dataForm.step2.edit.feeTotal =
        (!this.dataForm.step2.edit.feeAdmin
          ? 0
          : this.dataForm.step2.edit.feeAdmin) +
        (!this.dataForm.step2.edit.feeMaterai
          ? 0
          : this.dataForm.step2.edit.feeMaterai) +
        (!this.dataForm.step2.edit.feeNotaris
          ? 0
          : this.dataForm.step2.edit.feeNotaris) +
        (!this.dataForm.step2.edit.feeInsuranceLife
          ? 0
          : this.dataForm.step2.edit.feeInsuranceLife) +
        (!this.dataForm.step2.edit.feeInsuranceLoss
          ? 0
          : this.dataForm.step2.edit.feeInsuranceLoss) +
        (!this.dataForm.step2.edit.feeInsuranceVehicle
          ? 0
          : this.dataForm.step2.edit.feeInsuranceVehicle);
    },
    calculateParticipateEditSubLoanAccount(type) {
      this.dataForm.step2.edit.totalParticipate =
        this.dataForm.step2.edit.participateBank +
        this.dataForm.step2.edit.participateCif;
      this.dataForm.step2.edit.nominalLoan = this.dataForm.step2.edit.participateBank;
      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
        this.calculateProyeksiAngsuranEditSubLoanAccount();
      }
    },
    changeProfitProjectionEditSubLoanAccount() {
      if (this.dataForm.step2.edit.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentEditSubLoanAccount();
        this.dataForm.step2.edit.nominalLoan = this.dataForm.step2.edit.participateBank;
        this.calculateProyeksiAngsuranEditSubLoanAccount();
      }
      this.calculateRateEffectiveAnnuallyEditSubLoanAccount();
    },
    // calculateRateSubLoanAccountEdit(triggerby) {
    //   this.dataForm.step2.edit.rateFlat = this.dataForm.step2.edit.rateMargin;
    //   if (triggerby === "manual_input") {
    //     if (
    //       this.dataForm.step2.edit.margin === false &&
    //       this.dataForm.step2.edit.nominalPurchase &&
    //       this.dataForm.step2.edit.rateMargin
    //     ) {
    //       const payload = {
    //         rateFlat: this.dataForm.step2.edit.rateMargin,
    //         principal: this.dataForm.step2.edit.nominalPurchase,
    //       };
    //       this.convertToEffectiveEditSubLoanAccount(
    //         payload.rateFlat,
    //         payload.principal
    //       );
    //     }
    //   }
    //   this.dataForm.step2.edit.totalMargin = this.dataForm.step2.edit.timePeriod
    //     ? (this.dataForm.step2.edit.rateMargin / 12) *
    //       this.dataForm.step2.edit.timePeriod
    //     : 0;
    //   this.dataForm.step2.edit.nominalMargin = this.dataForm.step2.edit
    //     .totalMargin
    //     ? (this.dataForm.step2.edit.totalMargin *
    //         this.dataForm.step2.edit.nominalPurchase) /
    //       100
    //     : 0;
    //   this.dataForm.step2.edit.totalSellingPrice = this.dataForm.step2.edit
    //     .nominalMargin
    //     ? this.dataForm.step2.edit.nominalMargin +
    //       this.dataForm.step2.edit.nominalPurchase
    //     : 0;
    //   this.dataForm.step2.edit.nominalLoan = this.dataForm.step2.edit.totalSellingPrice;
    //   this.dataForm.step2.edit.nominalInstallment =
    //     this.dataForm.step2.edit.nominalLoan /
    //     this.dataForm.step2.edit.timePeriod;
    // },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.referalOffice = resp.data.data;
          this.options.step1.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
          this.options.step2.referalOffice = resp.data.data;
          this.options.step2.add.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
          this.options.step2.referalOffice = resp.data.data;
          this.options.step2.edit.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
    onUpdateAo(props) {
      this.$refs.updateAoEditLoanAccountDialogRef.onOpenModal({
        accountOfficerHandleId: props.row.accountOfficerHandleId,
        accountOfficerCollectorId: props.row.accountOfficerCollectorId,
        loanAccountId: props.row.loanAccountId,
      });
    },
    onFinishUpdateAO() {
      this.property.listElement.step2.currentPage = 1;
      this.property.listElement.step2.rows = 0;
      this.getSubLoanAccount();
    },
    async getReferenceAccountNotaris() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/by-chart-of-account-code",
          payload: {
            savingCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_SAVING_CODE_TITIPAN_NOTARIS,
            chartOfAccountCode: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_COA_CODE_TITIPAN_NOTARIS,
            allBranchesCanSee: true,
          },
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountNumber} - ${index.mcif.cifAliasName}`;
            const value = index.accountNumber;
            this.options.step1.accountNotaris.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceAccountIsurance() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/by-chart-of-account-code",
          payload: {
            savingCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_SAVING_CODE_TITIPAN_ASURANSI,
            chartOfAccountCode: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_COA_CODE_TITIPAN_ASURANSI,
            allBranchesCanSee: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountNumber} - ${index.mcif.cifAliasName}`;
            const value = index.accountNumber;
            this.options.step1.titipanAsuransi.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  beforeMount() {
    this.getIdentityFromSession();
  },
  mounted() {
    this.getReferenceAccountIsurance();
    this.getReferenceAccountNotaris();
    this.defaultSetupStepper();
    this.getReferenceLoan();
    this.getReferenceLoanAkadType();
    this.getReferenceCollector();
    this.getReferenceAccountOfficer();
    this.getReferenceLoanGroup();
    this.getRefferenceLoanCollateral();
    this.getReferenceLoanGroupCategory();
    this.getReferenceOfficeReferal();
  },
};
