import EditLoanCollateralDecree from "./collateral-decree";
import EditLoanCollateralFixedAsset from "./collateral-fixed-asset";
import EditLoanCollateralSecurites from "./collateral-securities";
import EditLoanCollateralVehicle from "./collateral-vehicle";
import EditLoanCollateralPreciousMetal from "./collateral-precious-metal";

export default {
  name: "EditLoanCollateral",
  components: {
    EditLoanCollateralDecree,
    EditLoanCollateralFixedAsset,
    EditLoanCollateralSecurites,
    EditLoanCollateralVehicle,
    EditLoanCollateralPreciousMetal,
  },
};
